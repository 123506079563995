
import React ,{useState,useEffect, useRef}from "react"
import { FiEdit3, FiRefreshCcw } from "react-icons/fi";
import { BsFillReplyFill } from "react-icons/bs";
import DOMPurify from 'dompurify'
import './inbox.css';
import {AiOutlineArrowLeft, AiOutlineInbox, AiOutlineSend } from "react-icons/ai";
import { get_ApiManager, post_ApiManager, view_ApiManager } from "../ApiManager";
import moment from 'moment';
import swal from "sweetalert";
import ComposeModel from "./composeModel";
import { FaAngleLeft, FaAngleRight, FaSort, FaSortAmountDown} from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";

const Inbox = (props) => {
    const user = useState(JSON.parse(localStorage.getItem('user')));
    const [activeTab, setActiveTab] = useState('inbox');
    const [loading, setLoading] = useState(false);
    const [reply, setReply] = useState(false);
    const [showEmail, setShowEmail] = useState(false);
    const [allEmails, setAllEmails] = useState([]);
    const [emailDetail, setEmailDetail] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [from, setFrom] = useState(0);
    const [to, setTo] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [lastPage, setLastPage] = useState('');
    const [totalItem, setTotalItem] = useState('');
    const [header, setHeader] = useState('');
    const [replyText , setReplyText] = useState('');
    const [replyOf , setReplyOf] = useState('');
    const [selectedEmail , setSelectedEmail] = useState([]);
    const [showComposeModel, setShowComposeModel] = useState(false);
    const [allApps, setAllApps] = useState([]);
    const lastReplyRef = useRef(null);
    const [sortOrder, setSortOrder] = useState('asc');
    const navigate = useNavigate();

    useEffect(() => {
        if (!reply) {
        getAllEmails();
        }
        if (reply) {
          // Scroll to the desired div
          const targetDiv = document.getElementById('replyDivId');
          if (targetDiv) {
            targetDiv.scrollIntoView({ behavior: 'smooth' });
          }
        }
    }, [reply, currentPage, perPage]);
    

    const getAllEmails = () => {
        setLoading(true)
        let url = `getClientEmails/${user[0]['id']}`;
        console.log(url);
        view_ApiManager(url, currentPage, perPage,(res) => {
            setLoading(false)
           console.log('all-emails',res);
           setAllEmails(res.data.data['data']);
           setPerPage(res.data.data['per_page']);
           setCurrentPage(res.data.data['current_page']);
           setLastPage(res.data.data['last_page']);
           setTotalItem(res.data.data['total']);
           setFrom(res.data.data['from']);
           setTo(res.data.data['to']);
        },(err) => {
            console.log(err);
        });
    }

    const getSentEmail = (replyto, id) => {
        const data = {
            replyto: replyto,
            id: id,
        }
        let url = 'getSentEmail';
        console.log(data);
        post_ApiManager(data ,url,(res) => {
            console.log('email',res);
            setEmailDetail(res.data.data['emails'])
            setHeader(res.data.data['header'])
        },(err) => {
            console.log(err);
         });
    }

    const handleTabClick = (tab) => {
        if(tab === 'sent'){
           
        } else{
            getAllEmails();
        }
        setReply(false);
        setActiveTab(tab);
        setShowEmail(false);
    }
    const handleComposeCallBack = (type) => {
        if(type === 'edit'){
            sendNotification();
        }
        setShowComposeModel(false);
    }
    const composeNew = () => {
        const user = JSON.parse(localStorage.getItem('user'));
        let url = `getClientApps/${user['id']}`;
        console.log(url);
        get_ApiManager('',url,(res) => {
            console.log('all-apps',res);
            setAllApps(res.data.data)
            setShowComposeModel(true);
            sendNotification();

        },(err) => {
            console.log(err);
         });
    }
      //Call Notification bell Counter
    const sendNotification = () => {
        const socket = props.socket;
        console.log(socket)
        socket?.emit("emailSent");
    }

     const handleEmailClick = (emailId) => {
        navigate(`/email/${emailId}`);
    };

    return(
        <>
        <div className="row px-3 justify-content-end py-4 d-flex h-100">
        <div className="row px-xxl-3 mb-4">
        <div className="col-lg-2 col-12 col-md-5 col-xxl-2 form-bg px-2 bg-white border inbox-area py-3 align-items-stretch mb-3">
        <button  onClick={() => composeNew()} className="btn btn-danger" style={{fontFamily:'poppins', fontSize:'14px'}}><FiEdit3 className="icon-margin"/> Compose</button>
        <ul className="nav nav-tabs form-tabs d-flex flex-column res-size-14 border-none mt-3" id="myTabs">
             <li className={`nav-item w-100`}>
                <button className={`nav-link custom-nav-link w-100 ${activeTab === 'inbox' ? 'active' : ''}`} onClick={() => handleTabClick('inbox')}>
                <AiOutlineInbox />Inbox</button>
             </li>
             <li className={`nav-item w-100`}>
                <button className={`nav-link custom-nav-link w-100 ${activeTab === 'sent' ? 'active' : ''}`} onClick={() => handleTabClick('sent')}>
                <AiOutlineSend
                 />Sent</button>
            </li>
             </ul>

        </div>
        {loading ? '' :
        <div className="col-lg-10 col-12 col-md-7 col-xxl-10 border bg-white align-items-stretch mb-3">
        <div className="tab-content me-lg-2">
         {/* Inbox Section */}
        <div className={`tab-pane ${activeTab === 'inbox' ? 'show active' : 'hide'}`} id="inbox">
        {allEmails[0] ?
        <>
        {allEmails.map((value, index) => (
         <div className={`row px-3 py-3 no-left-right-border shadow-sm pointer ${value.is_read === 0 ? 'unread':'read'}`} 
            key={index} onClick={() => handleEmailClick(value.id)}>
            <div className='col-1 text-center'>{++index}</div>
            <div className='col-3'>
            Aptitude Migration 
            {value.counts > 0 && <small className='pl-2' style={{color:'grey'}}>{value.counts}</small>}
            {value.is_read === 0 && <>{moment(value.created_at).isSame(moment(), 'day') && (
                <div className="rounded-text-div">New</div>)}</>}
            </div>
            <div className='col-6'>{value.subject}</div>
            <div className='col-2 text-center size-14'> {moment(value.created_at).isSame(moment(), 'day')
                ? moment(value.created_at).format('h:mm A') // Show time if today
                : moment(value.created_at).format('D MMM YYYY') // Show date otherwise
            }</div>
         </div>))}
         </>:
        <div className="p-3 row border shadow-sm my-1 bg-white">
        <small className='text-center'>No new message</small>  
        </div>}

        </div>
        {/* Sent Section */}
        <div className={`tab-pane ${activeTab === 'sent' ? 'show active' : 'hide'}`} id="sent">
     
        </div>
        </div>   
        </div>}
        </div>
        </div>
        <ComposeModel
        show={showComposeModel}
        allApps={allApps}
        socket={props.socket}
        parentCallback={handleComposeCallBack}
         />
        </>
    )
}
export default Inbox