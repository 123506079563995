
import React from "react";
import * as FaIcons from 'react-icons/fa';
import { useNavigate} from "react-router-dom";


const Title = (props) => {
    const history = useNavigate();
    return(
        <>
        {props.back && !props.btn ? 
        <div className="row">
        <div className="col-12 text-left pl-0"> <span className="pb-2 pt-0">
            <button className="back-icon pl-0" onClick={() => props.backUrl ? history( '/main-settings') : history(-1)}>
            <FaIcons.FaArrowAltCircleLeft size={20}/><span className="back-text pl-lg-1">back</span>
            </button></span>
        </div>
        {props.title &&
        <div className="row">
        <div className="col-lg-9">
            <h4 className="page-title text-left custom-font4 bold pt-0 mb-0">{props.title}</h4>
        </div>
       
        </div>}
        </div>
        :
        <div className="row pt-2">
            {props.back && 
            <span className="pt-0 col-11">
            <div className="back-icon pl-lg-2 pointer" onClick={() => props.backUrl ? history( '/main-settings') : history(-1)}>
            <FaIcons.FaArrowAltCircleLeft size={20}/><span className="back-text pl-lg-1">back</span>
            </div></span>
            }
            <h4 className={`text-left custom-font4 bold ${!props.back ? 'pt-2' : ' pl-lg-3' } ${props.titleCol ? props.titleCol : 'col-lg-4'}`}>{props.title}</h4>
            {props.btn ? 
            <div className={`${props.btnCol ? props.btnCol : 'col-lg-6'}`}>
            <button  type="button"  
            onClick={() => props.btnUrl ? history(props.btnUrl) : props.parentCallback() }
            className={`${props.btnClass ? props.btnClass: ''}`}>{props.btnText}</button>
            </div>
            : ''}
            {/* <div className={`${props.branchClass ? props.branchClass : 'col-lg-8 pt-1'}`}>
            <select className={`${props.branchInnerClass ? props.branchInnerClass+ ' form-select app-form float-right ' : 'form-select app-form float-right'}`}
             onChange={(e) => props.parentCallback(e.target.value)}>
            <option selected value={'null'}>Select Branch</option>
            {[].concat(props.showBranches).sort((a, b) => a.agencyname > b.agencyname ? 1 : -1).map(value =>
            <option key={value.id} className="light" value={value.id}>{value.agencyname}</option> )}
            </select> 
            </div>} */}
        </div>
        }
        </>
    ) 
}
export default Title;