// Header.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './header.css'; // Make sure to create a corresponding CSS file

const Header = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const navigate = useNavigate(); // This hook gives you access to the navigation object

    const handleLogout = () => {
      // Clear the token (or any other authentication data) from local storage or state management
      localStorage.removeItem('token');
      // ... any other cleanup actions ...
  
      // Redirect the user to the login page
      navigate('/login');
    };
  
  return (
    user && user['name'] ?
    <header className="header">
      <h5 className="mb-0 mt-2 custom-font4 bold">
        Welcome {user['name']}
      </h5>
      <nav className="navigation">
        <ul className='mb-0 pb-0'>
          <li>
          <button className='btn btn-primary' onClick={handleLogout}>Logout</button></li>
        </ul>
      </nav>
    </header>:''
  );
};

export default Header;
