import React ,{useState,useEffect} from "react"
import { Link, useParams } from "react-router-dom"
import { get_ApiManager, post_ApiManager, view_ApiManager } from "../ApiManager"
import Title from "../GenericComponents/Title"
import './application.css'
import ProgressBar from 'react-bootstrap/ProgressBar'
import { main_url } from "../../utilies/Common"
import Moment from "react-moment"
import DOMPurify from "dompurify"
import { ImDownload, ImUpload } from "react-icons/im";
import { FaReply } from "react-icons/fa"
import { FiEdit3 } from "react-icons/fi"
import ComposeModel from "../InboxUs/composeModel"
import userlogo from '../../assets/chat.png';
import userlogo1 from '../../assets/chat2.png';
import moment from "moment";
import swal from "sweetalert";

const EducationDetail = (props) => {
    const { appid } = useParams();
    const countryCodePattern = /^\+\d+/;
    const [details, setGetDetail] = useState([]);
    const [remaining, setRemaining] = useState('');
    const [eduappCourse, setEduAppCourse] = useState([]);
    const [credit, setCredit] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(5);
    const [allNotes, setAllNotes] = useState([]);
    const [showComposeModel, setShowComposeModel] = useState(false);
    const [clientChat, setClientChat] = useState([]);
    const [showReply, setShowReply] = useState(false);
    const [replyError, setReplyError] = useState(null);
    const user = useState(JSON.parse(localStorage.getItem('user_id')));
    const [reply, setReply] = useState('');
    const [parentid, setParentId] = useState('');
    
    useEffect( () => {
        getDetails();
        getClientChat();
    }, []);

   const getDetails = () => {
      // setLoading(true);
      let url = `eduDetails/${appid}`;
      console.log(url);
      get_ApiManager('',url,(res) => {
         console.log('Edu-app-detail',res);
         setGetDetail(res.data.data['data']);
         setLoading(false);
         getNotes();
         let totalArray=[];
         let sum = 0.00;
         if(res.data.data['credit']){
            res.data.data['credit'].map(x => {
               return totalArray.push(Math.fround(x.credit_amount));
            })
            sum = totalArray.reduce((a,v) =>  a =  a+v, 0.00 );
            sum = parseFloat(sum).toFixed(2);
         }
         let remaining = parseFloat(res.data.data['data']['professional_cost'])-parseFloat(res.data.data['data']['paid_professtional_cost'])
         remaining = remaining-sum;
         remaining = remaining.toFixed(2);
         setRemaining(remaining);
         setCredit(sum)
      },(err) => {
         console.log(err);
      });
   }

   const getNotes = () => {
      let url = `clientsNotes/${appid}/${2}`;
      console.log(url);
      view_ApiManager(url, currentPage, perPage,(res) => {
         console.log('notes',res);
         setAllNotes(res.data.data);
         // setNotesTotal(res.data.data['total']);
      },(err) => {
         console.log(err);
      });

   }
      //Call Notification bell Counter
   const sendNotification = () => {
      const socket = props.socket;
      socket?.emit("emailSent");
   }
   const composeNew = () => {
      setShowComposeModel(true);
   }
   const handleComposeCallBack = (type) => {
      if(type === 'edit'){
         sendNotification();
      }
      setShowComposeModel(false);
   }
   const getClientChat = () => {
      let url = `getChatHistoryClient/${appid}/${'education'}`;
      console.log(url);
      get_ApiManager('', url,(res) => {
          console.log('c-chat',res)
          setClientChat(res.data.data)
      },(err) => {
          console.log(err);
      })
   }
   const setReplyValues = (parent_id) => {
      setParentId(parent_id)
      setShowReply(true)
   }
   const sendClientReply = () => {
      if(!reply){
          setReplyError('Enter your Reply!');
          return false
      } else{
          setReplyError('');
      }
      let url = `clientReplyToCmsEmail`;
      const data = {
          parentid: parentid,
          reply: reply,
          senderid: user[0],
      }
      console.log(data);
      post_ApiManager(data, url, (res) => {
          console.log('reply-sent',res);
          if(res.data.error) swal(" ", res.data.error, "error")
          else {
               sendNotification(); setReply(''); setReplyError(null); setShowReply(false); setParentId('');
               getClientChat();
             }  
      },(err) => {
          console.log(err);
      }); 
    }
  
   return(
      <>
      {/* {loading ? <Loader /> : */}
      <div className="row px-3">
      <Title title="Application Education Detail"
       titleCol="col-lg-6" back={true}
       btnCol="col-lg-6" btn={true} btnText="Home" btnClass="float-right btn btn-info" btnUrl="/dashboard"
       />
      <div className="p-4 bg-white  border mb-5 mt-2">
      <h5 className="text-center bold font-f-monst-sans bg-white-smoke size-18 p-3 mb-3 pt-3">Application Status</h5>
      <>
      <ul id="edu-progressbar">
      <li className="active" id="step1"><strong>Prospect</strong></li>
      
      <li className={details['status'] >= 2 || details['status'] <= -2 || (details['status'] === 0 || details['status'] === '0') ? 'active' : ''} id="step2">
      <strong>Application Received</strong></li>

      <li className={details['status'] >= 3 || details['status'] <= -3 || (details['status'] === 0 || details['status'] === '0') ? 'active' : ''} id="step3">
      <strong>Application Lodged</strong></li>
      {/* waiting 1*/}
      <li className={details['status'] >= 3 || details['status'] <= -3 || (details['status'] === 0 || details['status'] === '0') ? 'active' : ''} id="step2mid">Awaiting Outcome</li>

      <li className={(details['status'] >= 4 && details['status'] <= 13) || details['status'] >= 100 || details['status'] <= -100 || (details['status'] === 0 || details['status'] === '0')  ? 'active' : ''} id="step4">
      <strong>{
         (details['status'] === 4 || details['status'] === '4') || details['status'] <=-4 ? 'RFI Received' :
         (details['status'] === 5 || details['status'] === '5') || details['status'] <=-5 ? 'RFI Submitted' : 'RFI Received / Submitted'}
         </strong></li>

      <li className={((details['status'] >= 4 && details['status'] <= 13) || (details['status'] >= 100 || details['status'] <= -4 || (details['status'] === 0 || details['status'] === '0')))   ? 'active' : ''} id="step2mid"> 
      Awaiting Outcome</li>

      <li className={
              ( (details['status']>=101 || details['status']<=-101) 
         && (((details['status'] !== 104 || details['status']!=='104') && (details['status']!==-104 || details['status']!== '-104')))
         && (((details['status']!== 102 || details['status'] !== '102') && (details['status']!== -102 || details['status'] !== '-102')) )
         && (((details['status']!== 103 || details['status']!== '103') && (details['status']!== -103 || details['status']!== '-103')) ))
         || ((details['status'] === 0 || details['status'] === '0')|| 
         (details['status']>=6  || details['status']<=-6) )? 'active' : ''} id="step4">
      <strong>{
         (details['status'] === 101 || details['status'] === '101') ?
         'Application Refused'
         :details['status']>=102 || details['status']<=-102 || details['status']>=8 || details['status']<=-8 ?
         'Conditional Offer Received'
         :
         (details['status']>=101 || details['status']<=-101) 
      && ((details['status']!== 104 || details['status']!== '104') && (details['status'] !== -104 || details['status'] !== '-104')) 
      && ((details['status']!== 103 || details['status']!== '103') && (details['status'] !== -103 || details['status'] !== '-103'))  ?
      'Application Refused'
      :details['status'] >=6  || details['status'] <=-6 ? 'Offer Received' 
      : 'Offer Received / Refused'} </strong></li>

      {details['status'] > 0 ? (
      ((details['status']===100 || details['status']==='100') || details['status']<=-100) || (details['status']>=104 || details['status']<=-104) ? 
      <li className='active' id="step5"><strong>Offer Accepted
      </strong></li>
      :(details['status'] === 9 || details['status']==='9') || details['status' ]<=-9 ? 
      <li className='active' id="step5"><strong>Offer Accepted - COE Received</strong></li>
      :(details['status']===103 || details['status']==='103') || details['status']<=-103 ? 
      <li className='cancelled' id="step5"><strong>Offer Not Accepted</strong></li> 
      :
      (details['status'] === 7 || details['status'] === '7') || details['status' ]<=-7 ? 
      <li className='cancelled' id="step5"><strong>Discontinued</strong></li> 
      :
      <li id="step5"><strong> Offer Accepted
      </strong></li> )
      : <li className='cancelled' id="step5"><strong>Application Withdrawn</strong></li> }
      {details['status']>=104 || details['status']<=-104 ? 
      <li className="active" id="step5"><strong> Refund Applied
      </strong></li> :  
      <li className="" id="step2mid">Refund Applied</li>}
      </ul> 
      <ProgressBar animated now={
     (details['status'] === 1 || details['status'] === '1') ? 11 : 
     (details['status'] === 2 || details['status'] === '2') ? 22 : 
     (details['status'] === 3 || details['status'] === '3') ? 44: 
     (details['status'] === 4 || details['status'] === '4')|| (details['status'] === 5 || details['status'] === '5') ? 66 :
     (details['status'] === 6 || details['status'] === '6') || (details['status'] === 101 || details['status'] === '101')|| (details['status'] === 102 || details['status'] === '102')  || (details['status'] === 8 || details['status'] === '8')? 77 :
     (details['status'] === 7 || details['status'] === '7') || (details['status'] === 103 || details['status'] === '103') || (details['status'] === 100 || details['status'] === '100') || details['status'] <= -101 || (details['status'] === 0 || details['status'] === '0') ? 88 : 
     (details['status'] === 104 || details['status'] === '104') ? 100 :''} />
      </>
      <div className="row my-5 j-content-center px-lg-4">
      <div className="col-lg-5 px-lg-5 mx-lg-auto">
         <div className="d-flex">
         <span className="label bold">Email:</span>
         <label className="acc-label pl-lg-2">{details['clientemail']}</label>
         </div>

         <div className="d-flex ">
         <span className="label bold">Mobile:</span>
         <label className="acc-label pl-lg-2">{countryCodePattern.test(details['clientmobile']) ? details['clientmobile'] 
         : details['clientcountry'] === 'Pakistan' ? '+92'+details['clientmobile'] : '+61'+details['clientmobile']}
         </label>
         </div>

         <div className=" d-flex ">
         <span className="label bold">Provider:</span>
         <label className="acc-label pl-lg-2">{details['providername']}</label>
         </div>

         <div className=" d-flex ">
         <span className="label bold">{details['coursetype'] === 'single' ? 'Course:' : 'Package:'}</span>
         <label className="acc-label pl-lg-2">
         {eduappCourse && details['coursetype'] === 'single' ?
         <>
         {details['coursename']}
         </> : 
         <> <Link to={`/providers/provider-details/${details['providerid']}`}>
         {details['packagename']} </Link></>}</label>
         </div>

         {details['payment_file'] ?
         <div className="d-flex">
         <span className="label bold">Payment Receipt: </span>
         <label className="acc-label pl-lg-2"> <a target="_blank" rel="noreferrer" href={main_url()+'/eduapp/'+details['payment_file']}>{details['payment_file']}</a></label>
         </div>:''}

      

      </div>

      <div className="col-lg-5 px-lg-5 mx-lg-auto">

      <div className="d-flex">
      <span className="label bold">Case Manager:</span>
      <label className="acc-label pl-lg-2">{details['username']}</label>
      </div>

      <div className="d-flex">
      <span className="label bold">Date Lodged:</span>
      <label className="acc-label pl-lg-2">{details['datelodged'] ? details['datelodged'] : '--'}</label>
      </div>

      <div className="d-flex">
      <span className="label bold">Information Requested On:</span>
      <label className="acc-label pl-lg-2">{details['rfidate'] ? details['rfidate'] : '--'}</label>
      </div>

      <div className="d-flex">
      <span className="label bold">Invoice No:</span>
      <label className="acc-label pl-lg-2">{details['invoice_no'] ? details['invoice_no'] : '--'}</label>
      </div>

      <div className="d-flex">
      <button  onClick={() => composeNew()} className="btn btn-danger" style={{fontFamily:'poppins', fontSize:'14px'}}><FiEdit3 className="icon-margin"/> Compose New</button>
      </div>

      </div>


      </div>
      {/* Payment */}
      <div className="row mt-1">
      <h5 className="text-center bold font-f-monst-sans bg-white-smoke size-18 p-3 mb-3 pt-3">Payment Detail</h5>
      <div className="row mb-4 px-lg-5 py-lg-3">
         <div className="col-lg-4">
         <div className="d-flex">
         <span className="size-15 acc-label bold">Payment Status:</span>
         <label className="acc-label size-15 pl-lg-2">{details['payment_status'] ? details['payment_status'] : '--' }</label>
         </div>
         </div>

         <div className="col-lg-3">
         <div className="d-flex">
         <span className="size-15 acc-label bold">Credit:</span>
         <label className={`acc-label size-15 pl-lg-2 ${credit > 0.00 ? 'heading-blue bold' : ''}`}>{credit}</label>
         </div>
         </div>

         <div className="col-lg-2">
         <div className="d-flex">
         <span className="size-15 acc-label bold">Paid:</span>
         <label className={`acc-label size-15 pl-lg-2 ${details['paid_professtional_cost'] > 0.00 ? ' active bold ': ''}`}>{details['paid_professtional_cost']}</label>
         </div>
         </div>

         <div className="col-lg-3">
         <div className="d-flex">
         <span className="size-15 acc-label bold">Remaining:</span>
         <label className={`acc-label size-15 pl-lg-2 ${remaining > 0.00 ? 'bold text-danger' : ''}`}>{remaining}</label>
         </div>
         </div>

      </div>
      </div>
      <div className="mb-4 mt-3 boder">
      <div id="scroll" className="row">
      <h5 className="text-center bold font-f-monst-sans bg-white-smoke size-18 p-3 pt-3">Client Emails</h5>
      </div>
        {/* partner chat */}
      {clientChat.map((v, k) => 
         <div className="card mb-2" key={k}>
            <div className="row py-1 py-3" style={{background:'beige'}}>
            {v.conversation.map((value, index) => 
            <div className="row py-2" key={index}>
            <div className="col-1 text-center pl-3 pt-2">
            <img src={value.sender_type === 'cms' ? userlogo1 : userlogo} alt="User" className="img-fluid rounded-circle" style={{ width: '30px', height: '30px', objectFit: 'cover' }} />
            </div>
            <div className="col-8">
            <b className="capital size-16">{value.sender} {value.sender_type === 'client' ? '(you)' : ''}</b>
            <br/>
            <p className="size-16">{value.body}</p>
            </div>
            <div className='col-3 size-14 text-center'>
            {moment(value.created_at).format('MMM D, YYYY, h:mm A')} </div>
            </div>
            )}
            {showReply === true &&
            <div className="row my-2">
               <div className="col-1 text-center">
               <img src={userlogo1} alt="User" className="img-fluid rounded-circle" style={{ width: '40px', height: '40px', objectFit: 'cover' }} />
               </div>
               <div className="col-10">
               <b className="capital">Aptitude Migration</b>
               <br/>
               <textarea className="textarea form-control bg-white mt-2" rows="6" cols="60" placeholder="Reply..." onChange={(e) => setReply(e.target.value)}></textarea>
               {replyError && <><small className="error">{replyError}</small></>}
               <button className="btn btn-success px-4 my-2" onClick={() => sendClientReply()}>Send</button>
               </div>

            </div>}
            {showReply == false &&
            <div className="px-2">
                  <button className="btn btn-dark py-1 px-4 mx-3" onClick={() => setReplyValues(v.id)} style={{borderRadius:'20px'}}> 
                  <FaReply size={16}/> Reply</button>
            </div>}
            </div>
       </div>)} 
      </div>
      {/* Notes */}
      <>
      <div className="row mt-1">
      <h5 className="text-center bold font-f-monst-sans bg-white-smoke size-18 p-3 mb-0 pt-3">Notes</h5>
      </div>
      {allNotes.map((value, key) => 
      <div key={key} className="card my-2 border shadow">
      <div className="p-1 beige">
      <div className="row px-lg-2 px-2">
      <div key={key} className={`row font-monst mb-3 ${value.from === 'Aptitude Migration' ? 'shadow' : ''}`}>
      <div className={`row py-1 ${value.from === 'Aptitude Migration' ? 'border alice-blue px-4' : ''}`}>
      <div key={key} className="row mt-3">
      <div className="col-lg-6 pl-1">
      <p className="mb-0 bold mb-1 font-15 d-flex">
      <div className="pr-2">{value.from === 'Aptitude Migration' ? <FaReply /> : ''}</div>{value.from}</p>
      <div className={`size-15 mb-3 ${value.from === 'Aptitude Migration' ? ' pl-4' : ' pl-2'}`} style={{whiteSpace: 'pre-wrap'}} 
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value['notes'])}}></div>
      </div>
      <div className="col-lg-6 text-right">
      <div className="d-lg-flex j-content-right"><h5 className="size-14 pr-2">
      <Moment date={value.created_at} format="D MMM YYYY" />
      {' '}
      <Moment date={value.created_at} format="hh:mm A" /></h5>
      {value.from === 'Aptitude Migration' ?
      <ImDownload color="green" />:<ImUpload color="blue" /> }
      </div>
      {/* <p className="pl-2 bold label" style={{color: 'brown'}}>{(value.client_note_type === 'email') && <>
      <Link to={"/inbox"}><div className="btn btn-info">Send New Email</div></Link>
      </>}</p> */}
      </div>
      </div>
      </div>
      </div>
      </div>
      </div>
      </div>
      )}
      {/* <div className="text-center mt-2 mb-3">
      {showMore &&
      <button onClick={showNotes} className="btn btn-info"><FaIcons.FaAngleDown className="white" size={25}/></button>}
      {showLess &&
      <button onClick={hideNotes} className="btn btn-info"><FaIcons.FaAngleUp className="white" size={25}/></button>}
      </div> */}
      </>
      </div>
      </div>
      <ComposeModel
        show={showComposeModel}
        allApps={'no'}
        appname={eduappCourse && details['coursetype'] === 'single' ? details['coursename'] : details['packagename']}
        type={2}
        appid={appid}
        socket={props.socket}
        parentCallback={handleComposeCallBack}
      />
      </>
      )
}
export default EducationDetail