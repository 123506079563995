

import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";
import swal from 'sweetalert';
import { post_ApiManager } from '../ApiManager';

class ComposeModel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            coursename: "",
            appid: "",
            message:'',
            provider:'',
            type:'',
            assigned:'',
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    handleNotification = () => {
      const socket = this.props.socket;
      socket.emit("sendChatNotification");
    }

    handleSubmit() {
      const user = JSON.parse(localStorage.getItem('user'));
      let url = 'clientEmailtoCMS';
      const data ={
            appid: this.props.allApps === 'no' ? this.props.appid : this.state.appid,
            allApps: this.props.allApps,
            coursename: this.props.allApps === 'no' ? this.props.appname : this.state.coursename,
            clientid: user['id'],
            type: this.state.type,
            client:user['name'],
            provider: this.state.provider,
            message: this.state.message,
            assigned: this.state.assigned,
            senderid: user['id'],
      }
      console.log(data);
      post_ApiManager(data, url, (res) => {
          console.log('message-sent',res);
          if(res.data.error) swal(" ", res.data.error, "error")
          else {
              this.handleNotification();
              this.props.parentCallback('edit'); 
              swal("Success!", res.data.message, "success"); 
             }  
      },(err) => {
          console.log(err);
      }); 
    }

    handleModal(){
        this.props.parentCallback(false); 
    } 
    setAppValues = (e) => {
      e.preventDefault();
      const selectedOption = e.target.options[e.target.selectedIndex];
    
      this.setState({
        appid: e.target.value,
        coursename: selectedOption.text, // Get the text of the selected option
        provider: selectedOption.getAttribute('data-provider'), // Get the provider value
        type: selectedOption.getAttribute('data-type'), // Get the type value
        assigned: selectedOption.getAttribute('data-assigned'),
      });
    }
    

   render(){ 
        return(
        <>
        <Modal aria-labelledby="contained-modal-title-vcenter" centered  show={this.props.show} onHide={()=>this.handleModal()}> 
        <Modal.Header closeButton={(this.props.endButton === true) ? "" : true}><div className="text-center col-11 font-poppin">Compose New</div></Modal.Header> 
        <Modal.Body className="ml-2 mr-2">
        <form action='' onSubmit={this.handleSubmit}>
        <div className="row">
        <div className="form-group col-12">
        {/* <label className="font-poppin mb-1">Subject:</label>
        <input name="subject" placeholder='Please enter' onChange={this.handleChange}  className="card-text form-control acc-form font-poppin col-12"/> */}
        </div>
        {this.props.allApps === 'no' ?
        ''
        :
        <div className="form-group col-12">
        <label className="font-poppin mb-1">Select Application:</label>
        <select onChange={(e) => this.setAppValues(e)} className="form-select acc-form form-select-lg" name="appid">
        <option value="">Please Select</option>
        {this.props.allApps.map(head => (
          <option key={head.appid} value={head.appid} data-provider={head.provider} data-type={head.type} data-assigned={head.assigned} >
            {head.coursename ? head.coursename : head.packagename}
          </option>
        ))}
        <option value={0}>Direct Inquiry to Aptitude Migration</option>
        </select>
        </div>}
        <div className="form-group col-12">
        <textarea className="textarea form-control app-form font-poppin" placeholder="Please enter your message here..." 
        name="message" onChange={this.handleChange} rows="4" cols="60"></textarea>
        </div>
        </div>
        </form>
        </Modal.Body>
        <Modal.Footer>  
        <Button className="btn btn-danger" onClick={()=>this.handleModal()}>Cancel</Button>
        <Button className="btn btn-success" onClick={this.handleSubmit}>Send</Button>
        </Modal.Footer>
        </Modal>
        </>
        )
    }
}
export default ComposeModel