import React, { useEffect, useState } from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/css/bootstrap.css';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import Login from './components/Login/login';
import Dashboard from './components/Dashboard/dashboard';
import PrivateRoute from './components/PrivateRoute';
import Sidebar from './components/Sidebar/sidebar';
import './App.css';
import Logout from './components/Logout';
import Header from './components/Header/header';
import Profile from './components/Profile/profile';
import EducationDetail from './components/Applications/educationDetail';
import EducationApps from './components/Applications/eduApplications';
import MigrationApps from './components/Applications/migApplications';
import Inbox from './components/InboxUs/inbox';
import MigrationDetail from './components/Applications/migrationDetail';
import Payment from './components/Payment/payment';
import { io } from 'socket.io-client'
import swal from 'sweetalert';
import { get_ApiManager } from './components/ApiManager';
import EditInvoicePDF from './components/Invoice/EditInvoicePdf';
import EmailDetailComponent from './components/InboxUs/EmailDetail';

function App() {
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);
  const [hideSideBar, setHideSideBar] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSidebarExpanded(isSidebarExpanded);
    };
    if (window.innerWidth > 768) {
      setHideSideBar(true);
    } 

    // Add resize listener
    window.addEventListener('resize', handleResize);

    // Set the initial state
    handleResize();

    // Remove listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
    
  }, [isSidebarExpanded]);


    const toggleSidebar = () => {
      // Only toggle if window width is greater than 768px
      if (window.innerWidth > 768) {
        setIsSidebarExpanded(!isSidebarExpanded)
      }  
    };

  return (
    <Router>
      <AppContent
        isSidebarExpanded={isSidebarExpanded}
        hideSideBar={hideSideBar}
        toggleSidebar={toggleSidebar}
      />
    </Router>
  );
  }

function AppContent({ isSidebarExpanded, hideSideBar, toggleSidebar }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [chatNotiication, setChatNotification] = useState(0);
  const [socket, setSocket] = useState(null); 
  const navigate = useNavigate();
  const location = useLocation(); // Now useLocation is used correctly within the Router context
  const shouldHideSidebar = location.pathname === '/login' || location.pathname === '/' ;

  useEffect(() => {
    const newSocket = io("https://sockets.aptitudemigration.com.au:3002/");
    setSocket(newSocket);
    console.log(newSocket)
    return () => newSocket.close();

  }, []);

  useEffect(() => {
    socket?.on("checkClientLogin", (clientid) => {
      console.log('checkClientLogin');
      checkLoginStatus(clientid['clientid']);
      console.log(clientid['clientid']);
    });
  },[socket]);

  useEffect(() => {
    socket?.on("getClientChatNotification", () => {
      console.log('chat-notification');
      getChatNotification();
    });
  },[socket])

  useEffect(() => {
    checkToken();
   },[]);

   const checkToken = () => {
      const token = localStorage.getItem('token');
       if (token) {
        let url = 'checkClientToken';
        get_ApiManager('', url,(res) => {
          console.log('checkToken',res);
          if(res.data.auth === false){
            localStorage.clear();
            if(location.pathname !== '/login'){
              swal({title: "Session Expired",text: 'Please log in again!', icon: "warning", type: "error", timer: 1000})
              navigate('/login');
            }
          } 
        },(err) => {
            console.log(err);
        });
       } 
  }
  const getChatNotification = () => {
    let url = 'getClientChatNotification';
    get_ApiManager('', url,(res) => {
      console.log('chat-count',res);
      console.log('played');
      setChatNotification(res.data.data)
    },(err) => {
        console.log(err);
    });
   }
   const checkLoginStatus = (clientid) => {
    const user = JSON.parse(localStorage.getItem('user'));
    // if (clientid == user['id']) {
      let url = 'checkLoginStatus';
      get_ApiManager('', url,(res) => {
        console.log('checkLoginStatus',res);
        if(res.data.data === 0 || res.data.data === '0'){
          localStorage.clear();
          console.log('navigate');
          navigate('/login');
        } 
      },(err) => {
          console.log(err);
      });
  }

  return (
    <div className="app-container">
      {!shouldHideSidebar && (
        <>
        <Sidebar isExpanded={isSidebarExpanded} hideSideBar={hideSideBar} toggleSidebar={toggleSidebar} />
        <Header />
        </>
      )}
      <div className={`${hideSideBar ? 'main-content': ''} ${isSidebarExpanded && !shouldHideSidebar ? 'sidebar-expanded' : 'sidebar-collapsed'}`}>
        <Routes>
          <Route path="/login" element={localStorage.getItem('token') ? <Navigate to="/dashboard"/> : <Login />} />
          <Route
          path="/"
          element={localStorage.getItem('token') ? <Navigate to="/dashboard"/> : <Login />}
        />
       <Route path="/" element={<Navigate replace to="/login" />} />

        <Route exact path="/logout" element={<Logout />} />
          <Route path="/dashboard" element={<PrivateRoute> <Dashboard /></PrivateRoute>} />
          <Route path="/profile" element={<PrivateRoute> <Profile /></PrivateRoute>} />
          <Route path="/education-apps" element={<PrivateRoute> <EducationApps /></PrivateRoute>} />
          <Route path="/migration-apps" element={<PrivateRoute> <MigrationApps /></PrivateRoute>} />
          <Route path="/education-application/:appid" element={<PrivateRoute> <EducationDetail socket={socket} /></PrivateRoute>} />
          <Route path="/migration-application/:appid" element={<PrivateRoute> <MigrationDetail socket={socket}/></PrivateRoute>} />
          <Route path="/inbox" element={<PrivateRoute> <Inbox socket={socket} /></PrivateRoute>} />
          <Route path="/payment" element={<PrivateRoute> <Payment /></PrivateRoute>} />
          <Route path="/preview/invoice" element={<PrivateRoute> <EditInvoicePDF /></PrivateRoute>} /> 
          <Route path="/email/:id" element={<PrivateRoute isLoggedIn={isLoggedIn}> <EmailDetailComponent socket={socket} /></PrivateRoute>} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
