
import React, { Component } from 'react';
import { get_ApiManager, post_ApiManager } from "../ApiManager";
import { genders } from "../../utilies/Constants";
import './profile.css'
import ClientInfoSwitch from '../GenericComponents/ClientInfoSwitch';
import swal from 'sweetalert';
import Title from '../GenericComponents/Title';


class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            details:[],
            activeTab: 'basic',
            bName:'',
            bEmail:'',
            bGender:'',
            bCity:'',
            bCountry:'',
            bContact:'',
            firstName:'',
            middleName:'',
            lastName:'',
            dob:'',
            relationship:'',
            authEmail:'',
            authName:'',
            authContact:'',
            authEmail2:'',
            anotherName:0,
            clientOtherName:[],
            givenName:'',
            familyName:'',
            reason:'',
            cnicBtn:0,
            cnicNo:'',
            cnicIssueDate:'',
            cnicExpiryDate:'',
            passportBtn:0,
            passportNo:'',
            passportIssueDate:'',
            passportExpiryDate:'',
            passportCountry:'',
            licenseBtn:0,
            licenseNo:'',
            licenseExpiryDate:'',
            addressHistoryBtn:0,
            birthCertBtn:0,
            marriageCertBtn:0,
            englishTestBtn:0,
            oshcBtn:0,
            visaHistoryBtn:0,
            travelBtn:0,
            policeCheckBtn:0,
            parentHistoryBtn:0,
            siblingHistoryBtn:0,
            sQualificationBt:0,
            sCnicBtn:0,
            sPasportBtn:0,
            sEmployementBtn:0,
            sQualificationBtn:0,
            sBirthCertBtn:0,
            sDrivingBtn:0,
            sMarriageBtn:0,
            degreeList: [{ name : "", institute: "" ,campus: "",startdate:"", enddate:"", postcode:"", course_status:""}],
            experienceList: [{ name : "", duties: "" , startdate:"", enddate:"", business_address:"", exp_country:""}],
            visaGrantedList: [{ applicationdate : "", visatype: "" , expirydate:"", issuedate:"", visaplace:"", country:""}],
            visaRefusalList: [{ applicationdate : "", visatype: "" , refusalreason:"",visaplace:"", refusaldate:"", country:""}],
            engTestList: [{ expirydate : "", issuedate: "" , referencenumber:"", testtype:"", expirydatemig:""}],
            oshcList: [{ providername : "", issuedate: "" , membernumber:"", expirydate:""}],
            addressList: [{ h_address : "", h_city : "" , h_post_code:"", h_country:"", h_start_date:"",h_end_date:""}],
            parentHistoryList: [{ p_sex : "", p_town : "" , p_relation:"", p_family_name:"", p_any_other_name :"", p_citizen:"" , p_dob: "", p_state :"", p_cur_resi:"",p_given_name:"", p_country:""}],
            birthCertiList: [{ bci_no : "", bci_country: ""}],
            marriageCert: [{ first_name : "", middle_name: "", last_name:"", idientity_no: ""}],
            siblingHistoryList: [{ p_sex : "", p_town : "" , p_relation:"", p_family_name:"", p_any_other_name :"", p_citizen:"" , p_dob: "", p_state :"", p_cur_resi:"",p_given_name:"", p_country:""}],
            policeCheckList: [{ issuingauthority: "", referencenumber: "", issuedate:"", expirydate: ""}],
            skillList: [{ assessingbody : "", referencenumber: "", assessingdate:"", expirydate: ""}],
            travelList: [{ fromdate : "", todate: "", visitreason:"", country: ""}],
            spouse: [{ firstname : "", middlename: "", lastname:"", gender: "", birthplace: "", dob:"", citizenship:"", residencecountry: ""}],
            sCnicList: [{ cnicno : "", issuedate: "", expirydate:""}],
            sPassportList: [{ passportno : "", issuedate: "", expirydate:"", country:""}],
            sQualificationList: [{ name : "", institute: "", campus:"", postcode:"", startdate:"",  enddate:"" }],
            sEmployementList: [{ name : "", duties: "", startdate:"", enddate:"", business_address:"", exp_country:"" }],
            sBirthCertList: [{ bci_no : "", bci_country: "" }],
            sDrivingList: [{licenseno : "", expirydate: "" }],
            sMarriageList: [{first_name : "", middle_name: "", last_name:"", idientity_no:"",marriage_cert_country:"" }], 
            childList: [{firstname : "", middlename: "", lastname:"", gender:"", dob:"", relation:"", birthplace:"",citizenship:"", residencecountry:""}]
        }
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        // console.log('componentDidMount called');
        this.getDetails();
    }

    getDetails() {
        const user = JSON.parse(localStorage.getItem('user'));
        let url = 'getProfileDetail';
        get_ApiManager(user['id'], url, (res) => {
           console.log('client all detail',res);
           this.setState({
            bName: res.data.data['clients']['name'],
            bEmail: res.data.data['clients']['email'],
            bGender: res.data.data['clients']['gender'],
            bCity: res.data.data['clients']['city'],
            bCountry: res.data.data['clients']['country'],
            bContact: res.data.data['clients']['mobile'],
            firstName: res.data.data['other_details'] && res.data.data['other_details']['firstname'],
            middleName: res.data.data['other_details'] && res.data.data['other_details']['middlename'],
            lastName: res.data.data['other_details'] && res.data.data['other_details']['lastname'],
            dob: res.data.data['other_details'] && res.data.data['other_details']['dob'],
            relationship: res.data.data['other_details'] && res.data.data['other_details']['relationshipstatus'],
            authEmail: res.data.data['other_details'] && res.data.data['other_details']['auth_email'],
            authName: res.data.data['other_details'] && res.data.data['other_details']['auth_name'],
            authContact: res.data.data['other_details'] && res.data.data['other_details']['auth_contact'],
            authEmail2: res.data.data['other_details'] && res.data.data['other_details']['auth_email2'],
            clientOtherName: res.data.data['clientsothername'] && res.data.data['clientsothername'][0],
            givenName: res.data.data['clientsothername'] && res.data.data['clientsothername'].length > 0 &&  res.data.data['clientsothername'][0]['givenname'],
            familyName: res.data.data['clientsothername'] && res.data.data['clientsothername'].length > 0 &&  res.data.data['clientsothername'][0]['familyname'],
            reason: res.data.data['clientsothername'] && res.data.data['clientsothername'].length > 0 &&  res.data.data['clientsothername'][0]['reason'],
            anotherName: res.data.data['clientsothername'] && res.data.data['clientsothername'][0] ? 1 : 0,
            cnicBtn: res.data.data['clientcnic'] && res.data.data['clientcnic'][0] ? 1 : 0,
            cnicNo: res.data.data['clientcnic'] && res.data.data['clientcnic'].length > 0 &&  res.data.data['clientcnic'][0]['cnicno'],
            cnicIssueDate : res.data.data['clientcnic'] && res.data.data['clientcnic'].length > 0 &&  res.data.data['clientcnic'][0]['issuedate'],
            cnicExpiryDate: res.data.data['clientcnic'] && res.data.data['clientcnic'].length > 0 &&  res.data.data['clientcnic'][0]['expirydate'],
            passportBtn: res.data.data['clientpassport'] && res.data.data['clientpassport'][0] ? 1 : 0,
            licenseBtn: res.data.data['clientlicense'] && res.data.data['clientlicense'][0] ? 1 : 0,
            licenseNo: res.data.data['clientlicense'] && res.data.data['clientlicense'].length > 0 && res.data.data['clientlicense'][0]['licenseno'],
            licenseExpiryDate : res.data.data['clientlicense'] && res.data.data['clientlicense'].length > 0 && res.data.data['clientlicense'][0]['expirydate'],
            passportNo: res.data.data['clientpassport'] && res.data.data['clientpassport'].length > 0 && res.data.data['clientpassport'][0]['passportno'],
            passportCountry: res.data.data['clientpassport'] && res.data.data['clientpassport'].length > 0 && res.data.data['clientpassport'][0]['country'],
            passportIssueDate: res.data.data['clientpassport'] && res.data.data['clientpassport'].length > 0 && res.data.data['clientpassport'][0]['issuedate'],
            passportExpiryDate: res.data.data['clientpassport'] && res.data.data['clientpassport'].length > 0 && res.data.data['clientpassport'][0]['expirydate'],
            addressHistoryBtn: res.data.data['addresshistory'] && res.data.data['addresshistory'][0] ? 1 : 0,
            addressList: res.data.data['addresshistory'],
            visaHistoryBtn: res.data.data['clientvisa'][0] || res.data.data['clientvisarefusal'][0] ? 1 : 0,
            visaGrantedList: res.data.data['clientvisa'] && res.data.data['clientvisa'].length > 0 ? res.data.data['clientvisa'] :  this.state.visaGrantedList,
            visaRefusalList: res.data.data['clientvisarefusal'] && res.data.data['clientvisarefusal'].length > 0 ? res.data.data['clientvisarefusal'] : this.state.visaRefusalList,
            travelBtn: res.data.data['clientsinternationaltravel'][0] ? 1 : 0,
            travelList: res.data.data['clientsinternationaltravel'] && res.data.data['clientsinternationaltravel'].length > 0 ? res.data.data['clientsinternationaltravel'] : this.state.travelList,
            policeCheckBtn:res.data.data['clientpolicycheck'][0] ? 1 : 0,
            policeCheckList: res.data.data['clientpolicycheck'] && res.data.data['clientpolicycheck'].length > 0 ? res.data.data['clientpolicycheck'] : this.state.policeCheckList,
            parentHistoryBtn: res.data.data['parent_history'][0] ? 1 : 0,
            parentHistoryList: res.data.data['parent_history'] && res.data.data['parent_history'].length > 0 ? res.data.data['parent_history'] : this.state.parentHistoryList,
            siblingHistoryBtn: res.data.data['sibling_history'][0] ? 1 : 0,
            siblingHistoryList: res.data.data['sibling_history'] && res.data.data['sibling_history'].length > 0 ? res.data.data['sibling_history'] : this.state.siblingHistoryList,
            degreeList: res.data.data['clientdegree'] && res.data.data['clientdegree'].length > 0 ? res.data.data['clientdegree'] : this.state.degreeList,
            experienceList: res.data.data['clientexperience'] && res.data.data['clientexperience'].length > 0 ? res.data.data['clientexperience'] : this.state.experienceList,
            skillList: res.data.data['clientskills'] && res.data.data['clientskills'].length > 0 ? res.data.data['clientskills'] : this.state.skillList,
            spouse: res.data.data['spouse'] && res.data.data['spouse'].length>0 ? res.data.data['spouse'] : this.state.spouse,
            sCnicBtn:res.data.data['spousecnic'] && res.data.data['spousecnic'][0] ? 1 : 0,
            sCnicList: res.data.data['spousecnic'] && res.data.data['spousecnic'].length > 0 ? res.data.data['spousecnic'] : this.state.sCnicList,
            sPasportBtn: res.data.data['spousepassport'] && res.data.data['spousepassport'][0] ? 1 : 0,
            sPassportList: res.data.data['spousepassport'] && res.data.data['spousepassport'].length >0 ? res.data.data['spousepassport'] :this.state.sPassportList,
            sQualificationBtn: res.data.data['spousedegree'] && res.data.data['spousedegree'][0] ? 1 : 0,
            sQualificationList: res.data.data['spousedegree'] && res.data.data['spousedegree'].length > 0 ? res.data.data['spousedegree'] : this.state.sQualificationList,
            sEmployementBtn: res.data.data['spouseexperience'] && res.data.data['spouseexperience'] ? 1 : 0,
            sEmployementList: res.data.data['spouseexperience'] && res.data.data['spouseexperience'].length > 0 ? res.data.data['spouseexperience'] : this.state.sEmployementList,
            sBirthCertBtn: res.data.data['bci_data'] && res.data.data['bci_data'][0] ? 1 : 0,
            sBirthCertList: res.data.data['bci_data'] && res.data.data['bci_data'].length > 0 ? res.data.data['bci_data'] : this.state.sBirthCertList,
            sDrivingBtn: res.data.data['spouselicense'] && res.data.data['spouselicense'][0] ? 1 : 0,
            sDrivingList: res.data.data['spouselicense'] && res.data.data['spouselicense'].length > 0 ? res.data.data['spouselicense'] : this.state.sDrivingList,
            sMarriageBtn: res.data.data['sp_client_marriage'] && res.data.data['sp_client_marriage'][0] ? 1 : 0,
            sMarriageList: res.data.data['sp_client_marriage'] && res.data.data['sp_client_marriage'].length > 0 ? res.data.data['sp_client_marriage']: this.state.sMarriageList,
            childList: res.data.data['children'] && res.data.data['children'].length > 0 ? res.data.data['children']: this.state.childList,
           })
        },(err) => {
            console.log(err);
         });
    }
     // Function to handle tab click
    handleTabClick = (tabId) => {
        this.setState({
        activeTab: tabId,
        });
    }
     //CallBack function to get changed status name and value and call api to save results
    handleCallback = (changed_status, key_name) => {
        if(key_name === 'another_name'){
            this.setState({anotherName: changed_status})
        } else if(key_name === 'cnic'){
            this.setState({cnicBtn: changed_status})
        } else if(key_name === 'passport'){
            this.setState({passportBtn: changed_status})
        } else if(key_name === 'license'){
            this.setState({licenseBtn: changed_status})
        } else if(key_name === 'address_history'){
            this.setState({addressHistoryBtn: changed_status})
        } else if(key_name === 'birth_certificate'){
            this.setState({birthCertBtn: changed_status})
        } else if(key_name === 'english_test'){
            this.setState({englishTestBtn: changed_status})
        } else if(key_name === 'marriage_certificate'){
            this.setState({marriageCertBtn: changed_status})
        } else if(key_name === 'oshc'){
            this.setState({oshcBtn: changed_status})
        } else if(key_name === 'visa_history'){
            this.setState({visaHistoryBtn: changed_status})
        } else if(key_name === 'travel'){
            this.setState({travelBtn: changed_status})
        } else if(key_name === 'police_check'){
            this.setState({policeCheckBtn: changed_status})
        } else if(key_name === 'parent_history'){
            this.setState({parentHistoryBtn: changed_status})
        } else if(key_name === 'sibling_history'){
            this.setState({siblingHistoryBtn: changed_status})
        } else if(key_name === 'spouse_qualification'){
            this.setState({sQualificationBtn: changed_status})
        } else if(key_name === 'spouse_cnic'){
            this.setState({sCnicBtn: changed_status})
        } else if(key_name === 'spouse_passport'){
            this.setState({sPasportBtn: changed_status})
        } else if(key_name === 'spouse_employement'){
            this.setState({sEmployementBtn: changed_status})
        } else if(key_name === 'spouse_qualification'){
            this.setState({sQualificationBtn: changed_status})
        } else if(key_name === 'spouse_birth'){
            this.setState({sBirthCertBtn: changed_status})
        } else if(key_name === 'spouse_marriage'){
            this.setState({sMarriageBtn: changed_status})
        } else if(key_name === 'spouse_driving'){
            this.setState({sDrivingBtn: changed_status})
        }
    }
     // handle click event of the Remove button
     handleRemoveClick = (list, index) => {
        if (list === 'degree') {
            const newList = [...this.state.degreeList];
            newList.splice(index, 1);
            this.setState({ degreeList: newList });
        } else if (list === 'experience') {
            const newList = [...this.state.experienceList];
            newList.splice(index, 1);
            this.setState({ experienceList: newList });
        } else if (list === 'visa_grant') {
            const newList = [...this.state.visaGrantedList];
            newList.splice(index, 1);
            this.setState({ visaGrantedList: newList });
        } else if (list === 'visa_refusal') {
            const newList = [...this.state.visaRefusalList];
            newList.splice(index, 1);
            this.setState({ visaRefusalList: newList });
        } else if (list === 'english_test') {
            const newList = [...this.state.engTestList];
            newList.splice(index, 1);
            this.setState({ engTestList: newList });
        } else if (list === 'oshc') {
            const newList = [...this.state.oshcList];
            newList.splice(index, 1);
            this.setState({ oshcList: newList });
        } else if (list === 'address_history') {
            const newList = [...this.state.addressList];
            newList.splice(index, 1);
            this.setState({ addressList: newList });
        } else if (list === 'parent_history') {
            const newList = [...this.state.parentHistoryList];
            newList.splice(index, 1);
            this.setState({ parentHistoryList: newList });
        } else if (list === 'sibling_history') {
            const newList = [...this.state.siblingHistoryList];
            newList.splice(index, 1);
            this.setState({ siblingHistoryList: newList });
        } else if (list === 'police_check') {
            const newList = [...this.state.policeCheckList];
            newList.splice(index, 1);
            this.setState({ policeCheckList: newList });
        } else if (list === 'travel') {
            const newList = [...this.state.travelList];
            newList.splice(index, 1);
            this.setState({ travelList: newList });
        } else if (list === 'spouse_qualification') {
            const newList = [...this.state.sQualificationList];
            newList.splice(index, 1);
            this.setState({ sQualificationList: newList });
        } else if (list === 'spouse_employement') {
            const newList = [...this.state.sEmployementList];
            newList.splice(index, 1);
            this.setState({ sEmployementList: newList });
        } else if (list === 'child') {
            const newList = [...this.state.childList];
            newList.splice(index, 1);
            this.setState({ childList: newList });
        }
    }
      
      // handle click event of the Add button
    handleAddClick = (list) => {
        if(list === 'degree'){
            this.setState(prevState => ({
                degreeList: [...prevState.degreeList, { name: "", institute: "", campus: "", startdate: "", enddate: "", postcode: "", course_status: "" }]
            }));
        } else if (list === 'experience') {
            this.setState(prevState => ({
              experienceList: [...prevState.experienceList, { name: "", duties: "", startdate: "", enddate: "", business_address: "", exp_country: "" }]
            }));
        } else if (list === 'visa_grant') {
            this.setState(prevState => ({
              visaGrantedList: [...prevState.visaGrantedList, { applicationdate: "", visatype: "", expirydate: "", issuedate: "", visaplace: "", country: "" }]
            }));
        } else if (list === 'visa_refusal') {
            this.setState(prevState => ({
              visaRefusalList: [...prevState.visaRefusalList, { applicationdate: "", visatype: "", refusalreason: "", refusaldate: "", visaplace: "", country: "" }]
            }));
        } else if (list === 'english_test') {
            this.setState(prevState => ({
              engTestList: [...prevState.engTestList, { expirydate: "", issuedate: "", referencenumber: "", testtype: "", expirydatemig: "" }]
            }));
        } else if (list === 'oshc') {
            this.setState(prevState => ({
              oshcList: [...prevState.oshcList, { providername: "", issuedate: "", membernumber: "", expirydate: "" }]
            }));
        } else if (list === 'address_history') {
            this.setState(prevState => ({
              addressList: [...prevState.addressList, { h_address: "", h_city: "", h_post_code: "", h_country: "", h_start_date: "", h_end_date: "" }]
            }));
        } else if (list === 'parent_history') {
            this.setState(prevState => ({
              parentHistoryList: [...prevState.parentHistoryList, { p_sex: "", p_town: "", p_relation: "", p_family_name: "", p_any_other_name: "", p_citizen: "", p_dob: "", p_state: "", p_cur_resi: "", p_given_name: "", p_country: "" }]
            }));
        } else if (list === 'sibling_history') {
            this.setState(prevState => ({
              siblingHistoryList: [...prevState.siblingHistoryList, { p_sex: "", p_town: "", p_relation: "", p_family_name: "", p_any_other_name: "", p_citizen: "", p_dob: "", p_state: "", p_cur_resi: "", p_given_name: "", p_country: "" }]
            }));
        } else if (list === 'police_check') {
            this.setState(prevState => ({
              policeCheckList: [...prevState.policeCheckList, { issuingauthority: "", referencenumber: "", issuedate: "", expirydate: "" }]
            }));
        } else if (list === 'travel') {
            this.setState(prevState => ({
              travelList: [...prevState.travelList, { fromdate: "", todate: "", visitreason: "", country: "" }]
            }));
        } else if (list === 'spouse_qualification') {
            this.setState(prevState => ({
              sQualificationList: [...prevState.sQualificationList, { name: "", institute: "", campus: "", postcode: "", startdate: "", enddate: "", status: "" }]
            }));
        } else if (list === 'spouse_employment') {
            this.setState(prevState => ({
              sEmploymentList: [...prevState.sEmploymentList, { name: "", duties: "", startdate: "", enddate: "", business_address: "", exp_country: "" }]
            }));
        } else if (list === 'child') {
            this.setState(prevState => ({
              childList: [...prevState.childList, {first_name : "", middle_name: "", last_name:"", gender:"", dob:"", relation:"", birthplace:"",citizenship:"", residencecountry:""}]
            }));
        }
    };
      // handle data
    data = (value, name, i, listname, title, col, inputType, titleCol) =>{
        return(
        <div className={`${col} form-group pl-0`}>
        <label className={`${titleCol ? titleCol : 'font-serif pt-lg-1 size-15 mb-0'} pt-lg-1 mb-1`}>{title}</label>
        <input type={inputType ? inputType : 'text'} name={name} className="form-control acc-form col-lg-11" 
        placeholder="Please Enter" onChange={e => this.handleInputChange(e, i, listname)}
        defaultValue={value && value} />
        </div>
        )
    }

    handleInputChange = (e, index, list) => {
        const { name, value } = e.target;
    
        if (list === 'degree') {
        const updatedList = [...this.state.degreeList];
        updatedList[index][name] = value;
        this.setState({ degreeList: updatedList });
        } else if (list === 'experience') {
        const updatedList = [...this.state.experienceList];
        updatedList[index][name] = value;
        this.setState({ experienceList: updatedList });
        } else if (list === 'visa_grant') {
        const updatedList = [...this.state.visaGrantedList];
        updatedList[index][name] = value;
        this.setState({ visaGrantedList: updatedList });
        } else if (list === 'visa_refusal') {
        const updatedList = [...this.state.visaRefusalList];
        updatedList[index][name] = value;
        this.setState({ visaRefusalList: updatedList });
        } else if (list === 'english_test') {
        const updatedList = [...this.state.engTestList];
        updatedList[index][name] = value;
        this.setState({ engTestList: updatedList });
        } else if (list === 'oshc') {
        const updatedList = [...this.state.oshcList];
        updatedList[index][name] = value;
        this.setState({ oshcList: updatedList });
        } else if (list === 'birth_certificate') {
        const updatedList = [...this.state.birthCertiList];
        updatedList[index][name] = value;
        this.setState({ birthCertiList: updatedList });
        } else if (list === 'marriage_certificate') {
        const updatedList = [...this.state.marriageCert];
        updatedList[index][name] = value;
        this.setState({ marriageCert: updatedList });
        } else if (list === 'address_history') {
        const updatedList = [...this.state.addressList];
        updatedList[index][name] = value;
        this.setState({ addressList: updatedList });
        } else if (list === 'parent_history') {
        const updatedList = [...this.state.parentHistoryList];
        updatedList[index][name] = value;
        this.setState({ parentHistoryList: updatedList });
        } else if (list === 'sibling_history') {
        const updatedList = [...this.state.siblingHistoryList];
        updatedList[index][name] = value;
        this.setState({ siblingHistoryList: updatedList });
        } else if (list === 'police_check') {
        const updatedList = [...this.state.policeCheckList];
        updatedList[index][name] = value;
        this.setState({ policeCheckList: updatedList });
        } else if (list === 'skill') {
        const updatedList = [...this.state.skillList];
        updatedList[index][name] = value;
        this.setState({ skillList: updatedList });
        } else if (list === 'travel') {
        const updatedList = [...this.state.travelList];
        updatedList[index][name] = value;
        this.setState({ travelList: updatedList });
        } else if (list === 'spouse') {
        const updatedList = [...this.state.spouse];
        updatedList[index][name] = value;
        this.setState({ spouse: updatedList });
        } else if (list === 'spouse_cnic') {
        const updatedList = [...this.state.sCnicList];
        updatedList[index][name] = value;
        this.setState({ sCnicList: updatedList });
        } else if (list === 'spouse_passport') {
        const updatedList = [...this.state.sPassportList];
        updatedList[index][name] = value;
        this.setState({ sPassportList: updatedList });
        } else if (list === 'spouse_qualification') {
        const updatedList = [...this.state.sQualificationList];
        updatedList[index][name] = value;
        this.setState({ sQualificationList: updatedList });
        } else if (list === 'spouse_employement') {
        const updatedList = [...this.state.sEmployementList];
        updatedList[index][name] = value;
        this.setState({ sEmployementList: updatedList });
        } else if (list === 'spouse_birth') {
        const updatedList = [...this.state.sBirthCertList];
        updatedList[index][name] = value;
        this.setState({ sBirthCertList: updatedList });
        } else if (list === 'spouse_driving') {
        const updatedList = [...this.state.sDrivingList];
        updatedList[index][name] = value;
        this.setState({ sDrivingList: updatedList });
        } else if (list === 'spouse_marriage') {
        const updatedList = [...this.state.sMarriageList];
        updatedList[index][name] = value;
        this.setState({ sMarriageList: updatedList });
        } else if (list === 'child') {
        const updatedList = [...this.state.childList];
        updatedList[index][name] = value;
        this.setState({ childList: updatedList });
        } 
    };   

    //Submit form and call api to fetch results
    onSubmit() {
        const user = JSON.parse(localStorage.getItem('user'));
        let url = `updateClientProfile/${user['id']}`;
        const data = {
           bname : this.state.bName,
           bemail: this.state.bEmail,
           bgender: this.state.bGender,
           bcontactno: this.state.bContact,
           bcity: this.state.bcity,
           bcountry: this.state.bCountry,
           firstname: this.state.firstName,
           middlename: this.state.MiddleName,
           lastname: this.state.lastName,
           dob: this.state.dob,
           relationstatus: this.state.relationship,
           auth_email: this.state.authEmail,
           auth_email2: this.state.authEmail2,
           auth_name: this.state.authName,
           auth_contact: this.state.authContact,
           anothernameradiobtn: this.state.anotherName,
           passportradiobtn: this.state.passportBtn,
           givenname: this.state.givenName,
           familyname: this.state.familyName,
           reason: this.state.reason,
           cnicradiobtn: this.state.cnicBtn,
           cnicno: this.state.cnicNo,
           cnicissuedate: this.state.cnicIssueDate,
           drivinglicenseradiobtn: this.state.licenseBtn,
           drivinglicenseno: (this.state.licenseNo === null) ? 'null' : this.state.licenseNo,
           drivinglicenseexpirydate: (this.state.licenseExpiryDate === null) ? 'null' : this.state.licenseExpiryDate,
           englishTestBtn: this.state.englishTestBtn,
           engTestList: (this.state.englishTestBtn === 1 || this.state.englishTestBtn === '1') ? this.state.engTestList: [null],
           cnicexpirydate: this.state.cnicExpiryDate,
           addressHistoryBtn: this.state.addressHistoryBtn,
           addressList: (this.state.addressHistoryBtn === 1 || this.state.addressHistoryBtn === '1') ? this.state.addressList : [null],
           degreeList: this.state.degreeList,
           experienceList: this.state.experienceList,
           skillList: this.state.skillList,
           birthCertBtn: this.state.birthCertBtn,
           birthCertList: (this.state.birthCertBtn === 1 || this.state.birthCertBtn === '1') ? this.state.birthCertiList: [null],
           marriageCertBtn: this.state.marriageCertBtn,
           marriageCert: (this.state.marriageCertBtn === 1 || this.state.marriageCertBtn === '1') ? this.state.marriageCert : [null],
           oshcBtn: this.state.oshcBtn,
           oshcList: (this.state.oshcBtn === 1 || this.state.oshcBtn === '1') ? this.state.oshcList: [null],
           visaradiobtn: this.state.visaHistoryBtn,
           visaGrantedList: (this.state.visaHistoryBtn === 1 || this.state.visaHistoryBtn === '1') ? this.state.visaGrantedList: [null],
           visaRefusalList: (this.state.visaHistoryBtn === 1 || this.state.visaHistoryBtn === '1') ? this.state.visaRefusalList: [null],
           travelBtn: this.state.travelBtn,
           travelList: (this.state.travelBtn === 1 || this.state.travelBtn === '1') ? this.state.travelList : [null],
           policeCheckBtn: this.state.policeCheckBtn,
           policeCheckList: (this.state.policeCheckBtn === 1 || this.state.policeCheckBtn === '1') ? this.state.policeCheckList : [null],
           sBirthCertBtn: this.state.sBirthCertBtn,
           sBirthCertList: (this.state.sBirthCertBtn === 1 || this.state.sBirthCertBtn === '1') ? this.state.sBirthCertList : [null],
           sEmployementBtn: this.state.sEmployementBtn,
           sEmployementList: (this.state.sEmployementBtn === 1 || this.state.sEmployementBtn === '1') ? this.state.sEmployementList : [null],
           sQualificationBtn: this.state.sQualificationBtn,
           sQualificationList: (this.state.sQualificationBtn === 1 || this.state.sQualificationBtn === '1') ? this.state.sQualificationList : [null],
           sCnicBtn: this.state.sCnicBtn,
           sCnicList: (this.state.sCnicBtn === 1 || this.state.sCnicBtn === '1') ? this.state.sCnicList : [null],
           sPassportBtn: this.state.sPasportBtn,
           sPassportList: (this.state.sPasportBtn === 1 || this.state.sPasportBtn === '1') ? this.state.sPassportList : [null],
           sDrivingBtn: this.state.sDrivingBtn,
           sDrivingList: (this.state.sDrivingBtn === 1 || this.state.sDrivingBtn === '1') ? this.state.sDrivingList : [null],
           childList: this.state.childList,
           parentHistoryBtn:this.state.parentHistoryBtn,
           parentHistoryList: (this.state.parentHistoryBtn === 1 || this.state.parentHistoryBtn === '1') ? this.state.parentHistoryList : [null],
           siblingHistoryBtn: this.state.siblingHistoryBtn,
           siblingHistoryList: (this.state.siblingHistoryBtn === 1 || this.state.siblingHistoryBtn === '1') ? this.state.siblingHistoryList : [null],
           sMarriageBtn: this.state.sMarriageBtn,
           sMarriageList: (this.state.sMarriageBtn === 1 || this.state.sMarriageBtn === '1') ? this.state.sMarriageList : [null],
           spouseList: this.state.spouse,
           passportno: this.state.passportNo,
           passportissuedate: this.state.passportIssueDate,
           passportexpirydate: this.state.passportExpiryDate,
           countryofpassport: this.state.passportCountry,
        }
        console.log(data);
        post_ApiManager(data, url, (res) => {
            console.log('profile updated',res);
            if(res.data.error) swal(" ", res.data.error, "error")
            else {
                this.getDetails();
                swal("Success!", res.data.message, "success"); 
               }  
        },(err) => {
            console.log(err);
        }); 
    }


    render(){
        const { activeTab } = this.state;
        return(
            <>
             <div className="row px-3">
             <Title back={true} />
             <div className="row m-auto h-100">
             <div className="my-2 container row m-auto px-xxl-2">
             <div className="col-lg-3 col-12 col-md-5 col-xxl-2 form-bg mt-2 px-2 bg-white area py-3 ">
             <ul className="nav nav-tabs form-tabs d-flex flex-column res-size-14 border-none" id="myTabs">
             <li className={`nav-item w-100 `}>
                <button className={`nav-link custom-nav-link w-100 ${activeTab === 'basic' ? 'active' : ''}`} onClick={() => this.handleTabClick('basic')}>
                <i className="fa fa-home"></i>Basic Information</button>
             </li>
             <li className={`nav-item w-100`}>
                <button className={`nav-link custom-nav-link w-100 ${activeTab === 'pInfo' ? 'active' : ''}`} onClick={() => this.handleTabClick('pInfo')}>
                <i className="fa-solid fa-landmark"></i>Personal Information</button>
            </li>
            <li className="nav-item w-100">
            <button className={`nav-link custom-nav-link w-100 ${activeTab === 'education' ? 'active' : ''}`} onClick={() => this.handleTabClick('education')}>
                <i className="fa-solid fa-circle-dollar-to-slot"></i>Education & Employment</button>
            </li>
            <li className="nav-item w-100">
            <button className={`nav-link custom-nav-link w-100 ${activeTab === 'certificate' ? 'active' : ''}`} onClick={() => this.handleTabClick('certificate')}>
                <i className="fa-solid fa-list"></i>Assessment & Certifications</button>
            </li>
            <li className="nav-item w-100">
                <button className={`nav-link custom-nav-link w-100 ${activeTab === 'spouse' ? 'active' : ''}`} onClick={() => this.handleTabClick('spouse')}>
                <i className="fa-solid fa-landmark"></i>Spouse Information</button>
            </li>
            <li className="nav-item w-100">
                <button className={`nav-link custom-nav-link w-100 ${activeTab === 'child' ? 'active' : ''}`} onClick={() => this.handleTabClick('child')}>
                <i className="fa-solid fa-landmark"></i>Child Information</button>
            </li>
             </ul>
             </div>
             <div className="col-lg-9 col-12 col-md-7 col-xxl-10">
             <div className="tab-content mt-2 me-lg-2">
             {/* Basic Section */}
            <div className={`tab-pane ${activeTab === 'basic' ? 'show active' : 'hide'}`} id="basic">
            <div className="row bg-white area justify-content-center ml-lg-3 mb-3">
            <div className="p-4">
                <h4 className="almost-done mb-3">Basic Information</h4>
            <div className="row mt-2">
            {/* Full Name */}
            <div className="form-group col-lg-6">
            <label className="font-serif pt-lg-1 size-16 mb-0">Full Name*</label>
            <input type="text" name="bname" className="form-control acc-form col-lg-11" 
            placeholder="Please Enter" defaultValue={this.state.bName} onChange={(e) => this.setState({bName: e.target.value})} />
            </div>

            {/* Email */}
            <div className="form-group col-lg-6">
            <label className="font-serif pt-lg-1 size-15 mb-0">Client Email*</label>
            <input type="text" name="bemail" className="form-control acc-form col-lg-12" 
            placeholder="Please Enter" defaultValue={this.state.bEmail} onChange={(e) => this.setState({bEmail: e.target.value})} />
            </div>

            {/* Gender */}
            <div className="form-group col-12 col-lg-4">
            <label className="font-serif pt-lg-1 size-15 mb-0" htmlFor="inputField">Gender*</label>
            <select data-live-search="true" 
            value={this.state.bGender} name="bgender" 
            onChange={(e) => this.setState({bGender: e.target.value})} className="form-select acc-form col-lg-11">
            <option value=''>Please Select</option>
            {genders.map((value)=> 
            <option key={value.value}
            value={value.value}>{value.title}</option>
            )}
            </select>
            </div>

            {/* Country */}
            <div className="form-group col-lg-4">
            <label className="font-serif pt-lg-1 size-15 mb-0">Country*</label>
            <input type="text" name="bcountry" className="form-control acc-form col-lg-11" 
            placeholder="Please Enter" defaultValue={this.state.bCountry} onChange={(e) => this.setState({bCountry: e.target.value})} />
            </div>

            {/* City */}
            <div className="form-group col-lg-4">
            <label className="font-serif pt-lg-1 size-15 mb-0">City</label>
            <input type="text" name="bcity" className="form-control acc-form col-lg-11" 
            placeholder="Please Enter" defaultValue={this.state.bCity} onChange={(e) => this.setState({bCity: e.target.value})} />
            </div>

            {/* Contact */}
            <div className="form-group col-lg-4">
            <label className="font-serif pt-lg-1 size-15 mb-0">Contact*</label>
            <input type="text" name="bcontactno" className="form-control acc-form col-lg-11" 
            placeholder="Please Enter" defaultValue={this.state.bContact} onChange={(e) => this.setState({bContact: e.target.value})} />
            </div>

            </div>
            </div>
            </div>
            <div className='row my-4 px-1'>  
            <button type="submit" onClick={this.onSubmit}  className="btn btn-success ml-2 pl-3 pr-3" >Save Changes</button>
            </div>
            </div>
             {/* Personal Section */}
            <div className={`tab-pane ${activeTab === 'pInfo' ? 'show active' : ''}`} id="pInfo">
            <div className='mb-3'>
            <div className="row bg-white area justify-content-center ml-lg-3">
            <div className="p-4">
                <h4 className="almost-done mb-3">Personal Information</h4>
            <div className="row mt-2">
            <>
            {/* First Name */}
            <div className="form-group col-lg-6">
            <label className="font-serif pt-lg-1 size-16 mb-0">First Name*</label>
            <input type="text" name="firstname" className="form-control acc-form col-lg-11" 
            placeholder="Please Enter" defaultValue={this.state.firstName} onChange={(e) => this.setState({firstName: e.target.value})} />
            </div>

            {/* Middle Name */}
            <div className="form-group col-lg-6">
            <label className="font-serif pt-lg-1 size-15 mb-0">Middle Name</label>
            <input type="text" name="middlename" className="form-control acc-form col-lg-12" 
            placeholder="Please Enter" defaultValue={this.state.middleName} onChange={(e) => this.setState({bMiddleName: e.target.value})} />
            </div>

            {/* Last Name */}
            <div className="form-group col-lg-4">
            <label className="font-serif pt-lg-1 size-15 mb-0">Last Name</label>
            <input type="text" className="form-control acc-form col-lg-11" 
            placeholder="Please Enter" defaultValue={this.state.lastName} name="lastname" onChange={(e) => this.setState({lastName: e.target.value})}  />
            </div>

            {/* Date of Birth */}
            <div className="form-group col-lg-4">
            <label className="font-serif pt-lg-1 size-15 mb-0">Date of Birth</label>
            <input type="date" name="dob" className="form-control acc-form col-lg-11" 
            placeholder="Please Enter" defaultValue={this.state.dob} onChange={(e) => this.setState({dob: e.target.value})} />
            </div>

            {/* Relationship Status */}
            <div className="form-group col-lg-4 col-12">
            <label className="font-serif pt-lg-1 size-15 mb-0">Relationship Status</label>
            <select data-live-search="true" name="relationship" onChange={(e) => this.setState({relationship: e.target.value})} className="form-select acc-form col-lg-11"
            value={this.state.relationship}>
            <option value=''>Please Select</option>
            <option value={'Married'}>Married</option>
            <option value={'Never Married'}>Single</option>
            <option value={'Divorced'}>Divorced</option>
            <option value={'Widowed'}>Widowed</option>
            <option value={'Separated'}>Separated</option>
            </select>
            </div>
            </>
            {/* Authorize Persons Info */}
            <>
            <div className="col-12">
            <h5 className="text-center bold font-f-monst-sans size-16 p-1 mb-0 pt-3">Authorised Person’s</h5>
            </div>

            {/* Authorised Email */}
            <div className="form-group col-lg-5">
            <label className="font-serif pt-lg-1 size-15 mb-0">Email</label>
            <input type="text" name="authEmail" className="form-control acc-form col-lg-11" 
            placeholder="Please Enter" onChange={(e) => this.setState({authEmail: e.target.value})}
            defaultValue={this.state.authEmail} />
            </div>

            {/* Authorised Name */}
            <div className="form-group col-lg-4">
            <label className="font-serif pt-lg-1 size-15 mb-0">Name</label>
            <input type="text" name="authName" className="form-control acc-form col-lg-11" 
            placeholder="Please Enter" onChange={(e) => this.setState({authName: e.target.value})}
            value={this.state.authName} />
            </div>

            {/* Authorised Contact */}
            <div className="form-group col-lg-3">
            <label className="font-serif pt-lg-1 size-15 mb-0">Contact</label>
            <input type="text" name="authContact" className="form-control acc-form col-lg-12" 
            placeholder="Please Enter" onChange={(e) => this.setState({authContact: e.target.value})}
            value={this.state.authContact} />
            </div>

            {/* Authorised Email 2 */}
            <div className="form-group col-lg-5">
            <label className="font-serif pt-lg-1 size-15 mb-0">Other Email </label>
            <input type="text" name="authEmail2" className="form-control acc-form col-lg-11" 
            placeholder="Please Enter" onChange={(e) => this.setState({authEmail2: e.target.value})}
            value={this.state.authEmail2} />
            </div>
            </>

            </div>
            </div>
            </div>
             {/* Add Other names */}
            <div className='px-2 pt-2 area bg-white my-2 ml-lg-3'>
            <div className='col-12 p-2 px-3'>
            <ClientInfoSwitch status={this.state.clientOtherName && this.state.clientOtherName.length ? 1 : this.state.anotherName} text="Known by another names?" keyName="another_name" parentCallback={this.handleCallback} />
            {(this.state.anotherName === 1 || this.state.anotherName === '1') &&
            <>
            <div className="row">
            <div className="form-group col-lg-4 pl-0">
            <label className="font-serif pt-lg-1 size-15 mb-0">Given Name*</label>
            <input type="text" name="givenname" className="form-control acc-form col-lg-11" 
                placeholder="Please Enter" onChange={(e) => this.setState({givenName: e.target.value}) } 
                defaultValue={this.state.givenName} />
            </div>
            <div className="form-group col-lg-4 pl-0">
            <label className="font-serif pt-lg-1 size-15 mb-0">Family Name</label>
            <input type="text" name="familyname" className="form-control acc-form col-lg-11" 
                placeholder="Please Enter" onChange={(e) => this.setState({familyName: e.target.value}) }
                defaultValue={this.state.familyName} />
            </div>
            <div className="form-group col-lg-4 pl-0">
            <label className="font-serif pt-lg-1 size-15 mb-0">Reason for change</label>
            <input type="text" name="reason" className="form-control acc-form col-lg-11" 
                placeholder="Please Enter" onChange={(e) => this.setState({reason: e.target.value}) } 
                defaultValue={this.state.reason} />
            </div>
            </div>
            </>}
            </div>
            </div>
             {/* Cnic info */}
            <div className='px-2 pt-2 area bg-white my-2 ml-lg-3'>
            <div className='col-12 p-2 px-3'>
            <ClientInfoSwitch status={this.state.cnicBtn} text="Do you have National Identity Card?" keyName="cnic" parentCallback={this.handleCallback} />
            {(this.state.cnicBtn === 1 || this.state.cnicBtn === '1') &&
            <>
            <div className="row pl-lg-2">
            <div className="form-group col-lg-4 pl-0">
            <label className="font-serif pt-lg-1 size-15 mb-0">CNIC No:*</label>
            <input type="text" name="cnicno" className="form-control acc-form col-lg-11" 
                placeholder="Please Enter" onChange={(e) => this.setState({cnicNo: e.target.value}) } 
                defaultValue={this.state.cnicNo} />
            </div>
            <div className="form-group col-lg-4 pl-0">
            <label className="font-serif pt-lg-1 size-15 mb-0">CNIC Issue Date</label>
            <input type="date" name="cnicIssueDate" className="form-control acc-form col-lg-11" 
                placeholder="Please Enter" onChange={(e) => this.setState({cnicIssueDate: e.target.value}) }
                defaultValue={this.state.cnicIssueDate} />
            </div>
            <div className="form-group col-lg-4 pl-0">
            <label className="font-serif pt-lg-1 size-15 mb-0">CNIC Expiry Date</label>
            <input type="date" name="cnicExpiryDate" className="form-control acc-form col-lg-11" 
                placeholder="Please Enter" onChange={(e) => this.setState({cnicExpiryDate: e.target.value}) } 
                defaultValue={this.state.cnicExpiryDate} />
            </div>
            </div>
            </>
            }
            </div>
            </div>
             {/* Passport info */}
            <div className='px-2 pt-2 area bg-white my-2 ml-lg-3'>
            <div className='col-12 p-2 px-3'>
            <ClientInfoSwitch status={this.state.passportBtn} text="Do you have Passport?" keyName="passport" parentCallback={this.handleCallback} />
            {/* Passport */}
            {(this.state.passportBtn === 1 || this.state.passportBtn === '1') &&
            <>
            <div className="row pl-lg-2">
            <div className="form-group col-lg-6 pl-0">
            <label className="font-serif pt-lg-1 size-15 mb-0">Passport No*</label>
            <input type="text" name="passportno" className="form-control acc-form col-lg-11" 
            placeholder="Please Enter" onChange={(e) => this.setState({passportNo: e.target.value})} 
            defaultValue={this.state.passportNo} />
            </div>
            <div className="form-group col-lg-6 pl-0">
            <label className="font-serif pt-lg-1 size-15 mb-0">Country of Passport*</label>
            <input type="text" name="countryofpassport" className="form-control acc-form col-lg-11" 
            placeholder="Please Enter" onChange={(e) => this.setState({passportCountry: e.target.value}) } 
            defaultValue={this.state.passportCountry} />
            </div>
            <div className="form-group col-lg-6 pl-0">
            <label className="font-serif pt-lg-1 size-15 mb-0">Passport Issue Date*</label>
            <input type="date" name="passportissuedate" className="form-control acc-form col-lg-11" 
            placeholder="Please Enter" onChange={(e) => this.setState({passportIssueDate: e.target.value})}
            defaultValue={this.state.passportIssueDate} />
            </div>
            <div className="form-group col-lg-6 pl-0">
            <label className="font-serif pt-lg-1 size-15 mb-0">Passport Expiry Date*</label>
            <input type="date" name="passportexpirydate" className="form-control acc-form col-lg-11" 
            placeholder="Please Enter" onChange={(e) => this.setState({passportExpiryDate: e.target.value}) } 
            defaultValue={this.state.passportExpiryDate} />
            </div>
            </div>
            </>}
            </div>
            </div>
            {/* License info */}
            <div className='px-2 pt-2 area bg-white my-2 ml-lg-3'>
            <div className='col-12 p-2 px-3'>
            <ClientInfoSwitch status={this.state.licenseBtn} text="Do you have Driving License?" keyName="license" parentCallback={this.handleCallback} />
            {(this.state.licenseBtn === 1 || this.state.licenseBtn === '1') &&
            <>
            <div className="row pl-lg-2">
                <div className="form-group col-lg-6 pl-0">
                <label className="font-serif pt-lg-1 size-15 mb-0">Driving License No*</label>
                <input type="number" name="licenseno" className="form-control acc-form col-lg-11" 
                placeholder="Please Enter" onChange={(e) => this.setState({licenseNo: e.target.value})} 
                defaultValue={this.state.licenseNo} />
                </div>
                <div className="form-group col-lg-6 pl-0">
                <label className="font-serif pt-lg-1 size-15 mb-0">Driving License Expiry Date*</label>
                <input type="date" name="licenseexpirydate" className="form-control acc-form col-lg-11" 
                placeholder="Please Enter" onChange={(e) => this.setState({licenseExpiryDate: e.target.value}) } 
                defaultValue={this.state.licenseExpiryDate} />
                </div>
            </div>

            </>}
            </div>
            </div>
            {/* Address History */}
            <div className='px-2 pt-2 area bg-white my-2 ml-lg-3'>
            <div className='col-12 p-2 px-3'>
            <ClientInfoSwitch status={this.state.addressHistoryBtn} keyName="address_history" parentCallback={this.handleCallback} text="Address History" />
            {(this.state.addressHistoryBtn === 1 || this.state.addressHistoryBtn === '1') &&
            <>
            {this.state.addressList.map((x, i) => {
            return(
                <div key={i} className="row pl-lg-2">
                <h5 className="acc-label pt-lg-1 size-16 my-2 bold underline font-serif">Address {i+1}</h5>
                {this.data(x.h_address, 'h_address', i , 'address_history', "Address", "col-lg-4" )}
                {this.data(x.h_city, 'h_city', i , 'address_history', "City", "col-lg-4" )}
                {this.data(x.h_post_code, 'h_post_code', i , 'address_history', "Post Code", "col-lg-4")}
                {this.data(x.h_country, 'h_country', i , 'address_history', "Country", "col-lg-4" )}
                {this.data(x.h_start_date, 'h_start_date', i , 'address_history', "Start Date", "col-lg-4", 'date' )}
                {this.data(x.h_end_date, 'h_end_date', i , 'address_history', "End Date", "col-lg-4", 'date' )}
                <div className="d-flex mb-2">
                {this.state.addressList.length !== 1 && 
                <button className="btn btn-danger mr-2"  onClick={() => this.handleRemoveClick('address_history', i)}>Remove</button>}
                {this.state.addressList.length - 1 === i &&
                <button className="btn btn-primary" onClick={() => this.handleAddClick('address_history')}>Add More</button>}
                </div>
                </div>
            )
            })}
            </>}
            </div>
            </div>
            {/* Visa History */}
            <div className='px-2 pt-2 area bg-white my-2 ml-lg-3'>
            <div className='col-12 p-2 px-3'>
             <ClientInfoSwitch status={this.state.visaHistoryBtn} text="Visa History?" keyName="visa_history" parentCallback={this.handleCallback} />
            {(this.state.visaHistoryBtn === 1 || this.state.visaHistoryBtn === '1') &&
            <>
            <div className="row pl-2 pr-1 border">
            <h5 className="text-center bold font-f-monst-sans size-16 my-2 py-2">Visa Granted</h5>
            {this.state.visaGrantedList.map((x, i) => {
                return(
                    <>
                    <div className="form-group col-lg-4 pl-0">
                    <label className="font-serif pt-lg-1 size-15 mb-0 pt-lg-1 mb-1">Visa Application Type:*</label>
                    <input type="text" name="visatype" className="form-control acc-form col-lg-11" 
                    placeholder="Please Enter" onChange={e => this.handleInputChange(e, i, 'visa_grant')}
                    defaultValue={x.visatype && x.visatype} />
                    </div>
                    <div className="form-group col-lg-4 pl-0">
                    <label className="font-serif pt-lg-1 size-15 mb-0 pt-lg-1 mb-1">Application Date</label>
                    <input type="date" name="applicationdate" className="form-control acc-form col-lg-11" 
                    placeholder="Please Enter" onChange={e => this.handleInputChange(e, i, 'visa_grant')}
                    defaultValue={x.applicationdate && x.applicationdate} />
                    </div>
                    <div className="form-group col-lg-4 pl-0">
                    <label className="font-serif pt-lg-1 size-15 mb-0 pt-lg-1 mb-1">Issue/Grant Date</label>
                    <input type="date" name="issuedate" className="form-control acc-form col-lg-11" 
                    placeholder="Please Enter" onChange={e => this.handleInputChange(e, i, 'visa_grant')}
                    defaultValue={x.issuedate && x.issuedate} />
                    </div>
                    <div className="form-group col-lg-4 pl-0">
                    <label className="font-serif pt-lg-1 size-15 mb-0 pt-lg-1 mb-1">Expiry Date</label>
                    <input type="date" name="expirydate" className="form-control acc-form col-lg-11" 
                    placeholder="Please Enter" onChange={e => this.handleInputChange(e, i, 'visa_grant')}
                    defaultValue={x.expirydate && x.expirydate} />
                    </div>
                    <div className="form-group col-lg-4 pl-0">
                    <label className="font-serif pt-lg-1 size-15 mb-0 pt-lg-1 mb-1">Place of Visa issue/Grant</label>
                    <input type="text" name="visaplace" className="form-control acc-form col-lg-11" 
                    placeholder="Please Enter" onChange={e => this.handleInputChange(e, i, 'visa_grant')}
                    defaultValue={x.visaplace && x.visaplace} />
                    </div>
                    <div className="form-group col-lg-4 pl-0">
                    <label className="font-serif pt-lg-1 size-15 mb-0 pt-lg-1 mb-1">Country</label>
                    <input type="text" name="country" className="form-control acc-form col-lg-11" 
                    placeholder="Please Enter" onChange={e => this.handleInputChange(e, i, 'visa_grant')}
                    defaultValue={x.country && x.country} />
                    </div>
                    <div className="d-flex mb-lg-3 mt-lg-1">
                    {this.state.visaGrantedList.length !== 1 && <button
                    className="btn btn-danger mr-2"  onClick={() => this.handleRemoveClick('visa_grant', i)}>Remove</button>}
                    {this.state.visaGrantedList.length - 1 === i &&
                    <button className="btn btn-primary" onClick={() => this.handleAddClick('visa_grant')}>Add More</button>}
                    </div>
                    </>
                )
            })} 
            </div>
            {this.state.visaRefusalList.map((x, i) => {
            return(
                <>
                <div className="row pl-2 pr-1 border">
                <h5 className="text-center bold font-f-monst-sans size-16 my-2 py-2">Visa Refusal</h5>
                <div className="form-group col-lg-4 pl-0">
                <label className="font-serif pt-lg-1 size-15 mb-0 pt-lg-1 mb-1">Visa Application Type:*</label>
                <input type="text" name="visatype" className="form-control acc-form col-lg-11" 
                placeholder="Please Enter" onChange={e => this.handleInputChange(e, i, 'visa_refusal')}
                defaultValue={x.visatype && x.visatype} />
                </div>
                <div className="form-group col-lg-4 pl-0">
                <label className="font-serif pt-lg-1 size-15 mb-0 pt-lg-1 mb-1">Application Date</label>
                <input type="date" name="applicationdate" className="form-control acc-form col-lg-11" 
                placeholder="Please Enter" onChange={e => this.handleInputChange(e, i, 'visa_refusal')}
                defaultValue={x.applicationdate && x.applicationdate} />
                </div>
                <div className="form-group col-lg-4 pl-0">
                <label className="font-serif pt-lg-1 size-15 mb-0 pt-lg-1 mb-1">Decision Date</label>
                <input type="date" name="refusaldate" className="form-control acc-form col-lg-11" 
                placeholder="Please Enter" onChange={e => this.handleInputChange(e, i, 'visa_refusal')}
                defaultValue={x.refusaldate && x.refusaldate} />
                </div>
                <div className="form-group col-lg-4 pl-0">
                <label className="font-serif pt-lg-1 size-15 mb-0 pt-lg-1 mb-1">Place of Application</label>
                <input type="text" name="visaplace" className="form-control acc-form col-lg-11" 
                placeholder="Please Enter" onChange={e => this.handleInputChange(e, i, 'visa_refusal')}
                defaultValue={x.visaplace && x.visaplace} />
                </div>
                <div className="form-group col-lg-4 pl-0">
                <label className="font-serif pt-lg-1 size-15 mb-0 pt-lg-1 mb-1">Country</label>
                <input type="text" name="country" className="form-control acc-form col-lg-11" 
                placeholder="Please Enter" onChange={e => this.handleInputChange(e, i, 'visa_refusal')}
                defaultValue={x.country && x.country} />
                </div>
                <div className="form-group col-lg-8 pl-0">
                <label className="font-serif pt-lg-1 size-15 mb-0 pt-lg-1 mb-1">Refusal Reason</label>
                <textarea type="text" name="refusalreason" className="form-control acc-form" 
                placeholder="Please Enter" onChange={e => this.handleInputChange(e, i, 'visa_refusal')}
                defaultValue={x.refusalreason && x.refusalreason} />
                </div>
                <div className="d-flex mb-lg-3 mt-lg-1">
                {this.state.visaRefusalList.length !== 1 && <button
                className="btn btn-danger mr-2"  onClick={() => this.handleRemoveClick('visa_refusal', i)}>Remove</button>}
                {this.state.visaRefusalList.length - 1 === i &&
                <button className="btn btn-primary" onClick={() => this.handleAddClick('visa_refusal')}>Add More</button>}
                </div>
                </div>
                </>
            )
            })} 
            </>}
            </div>
            </div>
             {/* Travel Assessment */}
            <div className='px-2 pt-2 area bg-white my-2 ml-lg-3'>
            <div className='col-12 p-2 px-3'>
            <ClientInfoSwitch status={this.state.travelBtn} text="International Travel / Movement?" keyName="travel" parentCallback={this.handleCallback}/>
            {(this.state.travelBtn === 1 || this.state.travelBtn === '1') &&
            <>
            {this.state.travelList.map((x, i) => {
            return(
            <div className="row pl-lg-2">
            {this.data(x.fromdate, 'fromdate', i , 'travel', "From Date*", "col-lg-4", 'date' )}
            {this.data(x.todate, 'todate', i , 'travel', "To Date", "col-lg-4", "date")}
            {this.data(x.visitreason, 'visitreason', i , 'travel', "Country Visited", "col-lg-4")}
            {this.data(x.country, 'country', i , 'travel', "Reason for visit", "col-lg-4" )}
            <div className="d-flex">
            {this.state.travelList.length !== 1 && 
            <button className="btn btn-danger mr-2"  onClick={() => this.handleRemoveClick('travel', i)}>Remove</button>}
            {this.state.travelList.length - 1 === i &&
            <button className="btn btn-primary" onClick={() => this.handleAddClick('travel')}>Add More</button>}
            </div>
            </div>
            )
            })}
            </>}
            </div>
            </div>
            {/* Policy Check */}
            <div className='px-2 pt-2 area bg-white my-2 ml-lg-3'>
            <div className='col-12 p-2 px-3'>
            <ClientInfoSwitch status={this.state.policeCheckBtn} text="Have you applied, or currently hold Police check?" keyName="police_check" parentCallback={this.handleCallback} />
            {(this.state.policeCheckBtn === 1 || this.state.policeCheckBtn === '1' ) &&
            <>
            {this.state.policeCheckList.map((x, i) => {
            return(
                <div className="row pl-lg-2">
                <h5 className="acc-label pt-lg-1 size-16 my-2 bold underline font-serif">Policy Check {i+1}</h5>

                {this.data(x.issuingauthority, 'issuingauthority', i , 'police_check', "Country of Issue:*", "col-lg-4" )}
                {this.data(x.referencenumber, 'referencenumber', i , 'police_check', "Reference No", "col-lg-4" )}
                {this.data(x.issuedate, 'issuedate', i , 'police_check', "Expiry Date", "col-lg-4", 'date' )}
                {this.data(x.expirydate, 'expirydate', i , 'police_check', "Expiry Date", "col-lg-4", 'date' )}

                <div className="d-flex">
                {this.state.policeCheckList.length !== 1 && 
                <button className="btn btn-danger mr-2" onClick={() => this.handleRemoveClick('police_check', i)}>Remove</button>}
                {this.state.policeCheckList.length - 1 === i &&
                <button className="btn btn-primary" onClick={() => this.handleAddClick('police_check')}>Add More</button>}
                </div>
                </div>
            )
            })}
            </>}
            </div>
            </div>
            {/* Parent History */}
            <div className='px-2 pt-2 area bg-white my-2 ml-lg-3'>
            <div className='col-12 p-2 px-3'>
            <ClientInfoSwitch status={this.state.parentHistoryBtn} text="Do you know details of your parents?" keyName="parent_history" parentCallback={this.handleCallback}/>
            {(this.state.parentHistoryBtn === 1 || this.state.parentHistoryBtn === '1') &&
            <>
            {this.state.parentHistoryList.map((x, i) => {
            return(
                <div className="row pl-lg-2">
                <h5 className="pt-lg-1 size-16 my-2 bold underline font-serif">Parent Address {i+1}</h5>
                {this.data(x.p_relation, 'p_relation', i , 'parent_history', "Relationship to you", "col-lg-4" )}
                {this.data(x.p_family_name, 'p_family_name', i , 'parent_history', "Family Name", "col-lg-4" )}
                {this.data(x.p_given_name, 'p_given_name', i , 'parent_history', "Given Names", "col-lg-4")}
                {this.data(x.p_any_other_name, 'p_any_other_name', i , 'parent_history', "Any Other Names", "col-lg-4" )}
                {this.data(x.p_sex, 'p_sex', i , 'parent_history', "Sex", "col-lg-4", 'date' )}
                {this.data(x.p_dob, 'h_p_dob', i , 'parent_history', "Date Of Birth", "col-lg-4", )}
                {this.data(x.p_town, 'p_town', i , 'parent_history', "Town/City", "col-lg-4",  )}
                {this.data(x.p_state, 'p_state', i , 'parent_history', "State/Province/Region", "col-lg-4", )}
                {this.data(x.p_country, 'p_country', i , 'parent_history', "Country", "col-lg-4", )}
                {this.data(x.p_citizen, 'p_citizen', i , 'parent_history', "Citizenship(s) and year granted", "col-lg-4")}
                {this.data(x.p_cur_resi, 'p_cur_resi', i , 'parent_history', "Country of current residence", "col-lg-4")}
                
                <div className="d-flex">
                {this.state.parentHistoryList.length !== 1 && 
                <button className="btn btn-danger mr-2"  onClick={() => this.handleRemoveClick('parent_history', i)}>Remove</button>}
                {this.state.parentHistoryList.length - 1 === i &&
                <button className="btn btn-primary" onClick={() => this.handleAddClick('parent_history')}>Add More</button>}
                </div>
                </div>
                )
            })}
            </>}
            </div>
            </div>
             {/* Sibling History */}
            <div className='px-2 pt-2 area bg-white my-2 ml-lg-3'>
            <div className='col-12 p-2 px-3'>
            <ClientInfoSwitch status={this.state.siblingHistoryBtn} text="Do you know details of your siblings?" keyName="sibling_history" parentCallback={this.handleCallback} />
            {(this.state.siblingHistoryBtn === 1 || this.state.siblingHistoryBtn === '1') &&
            <>
            {this.state.siblingHistoryList.map((x, i) => {
            return(
                <div className="row pl-lg-2">
                <h5 className="pt-lg-1 size-16 my-2 bold underline font-serif">Sibling Detail {i+1}</h5>
                {this.data(x.p_relation, 'p_relation', i , 'sibling_history', "Relationship to you", "col-lg-4" )}
                {this.data(x.p_family_name, 'p_family_name', i , 'sibling_history', "Family Name", "col-lg-4" )}
                {this.data(x.p_given_name, 'p_given_name', i , 'sibling_history', "Given Names", "col-lg-4")}
                {this.data(x.p_any_other_name, 'p_any_other_name', i , 'sibling_history', "Any Other Names", "col-lg-4" )}
                {this.data(x.p_sex, 'p_sex', i , 'sibling_history', "Sex", "col-lg-4", 'date' )}
                {this.data(x.p_dob, 'h_p_dob', i , 'sibling_history', "Date Of Birth", "col-lg-4", )}
                {this.data(x.p_town, 'p_town', i , 'sibling_history', "Town/City", "col-lg-4",  )}
                {this.data(x.p_state, 'p_state', i , 'sibling_history', "State/Province/Region", "col-lg-4", )}
                {this.data(x.p_country, 'p_country', i , 'sibling_history', "Country", "col-lg-4", )}
                {this.data(x.p_citizen, 'p_citizen', i , 'sibling_history', "Citizenship(s) and year granted", "col-lg-4")}
                {this.data(x.p_cur_resi, 'p_cur_resi', i , 'sibling_history', "Country of current residence", "col-lg-4")}
                
                <div className="d-flex">
                {this.state.siblingHistoryList.length !== 1 && 
                <button className="btn btn-danger mr-2"  onClick={() => this.handleRemoveClick('sibling_history', i)}>Remove</button>}
                {this.state.siblingHistoryList.length - 1 === i &&
                <button className="btn btn-primary" onClick={() => this.handleAddClick('sibling_history')}>Add More</button>}
                </div>
                </div>
                )
            })}
            </>}
            </div>
            </div>

            <div className='row my-4 px-1'>
            <button type="submit" onClick={this.onSubmit}  className="btn btn-success ml-2 pl-3 pr-3" >Save Changes </button>
            </div>
            </div>
            </div>
             {/* Education and Employeement Section */}
            <div className={`tab-pane ${activeTab === 'education' ? 'show active' : 'hide'}`} id="education">
            <div className='mb-3'>
            {/* Education and Qualification */}
            <div className="row bg-white area justify-content-center ml-lg-3">
            <div className="p-4">
                <h4 className="almost-done mb-3">Education & Qualifications</h4>
            <div className="row mt-2">
            <>
            {this.state.degreeList.map((x, i) => {
            return(
            <div className="row pl-lg-2">
            <h5 className="my-2 bold underline font-serif size-16">Education {i+1}</h5>
            <div className="form-group col-lg-4 pl-0">
            <label className="font-serif pt-lg-1 size-15 mb-0">Qualification Name*</label>
            <input type="text" name="name" className="form-control acc-form col-lg-11" 
            placeholder="Please Enter" onChange={e => this.handleInputChange(e, i, 'degree')}
            defaultValue={x.name && x.name} />
            </div>
            <div className="form-group col-lg-4 pl-0">
            <label className="font-serif pt-lg-1 size-15 mb-0">Institute / University</label>
            <input type="text" name="institute" className="form-control acc-form col-lg-11" 
            placeholder="Please Enter" onChange={e => this.handleInputChange(e, i, 'degree')} 
            defaultValue={x.institute && x.institute}
            />
            </div>
            <div className="form-group col-lg-4 pl-0">
            <label className="font-serif pt-lg-1 size-15 mb-0">Address</label>
            <input type="text" name="campus" className="form-control acc-form col-lg-11" 
            placeholder="Please Enter" onChange={e => this.handleInputChange(e, i, 'degree')} 
            defaultValue={x.campus && x.campus}
            />
            </div>
            <div className="form-group col-lg-4 pl-0">
            <label className="font-serif pt-lg-1 size-15 mb-0">Course Start Date</label>
            <input type="date" name="startdate" className="form-control acc-form col-lg-11" 
            placeholder="Please Enter" onChange={e => this.handleInputChange(e, i, 'degree')} 
            defaultValue={x.startdate && x.startdate}
            />
            </div>
            <div className="form-group col-lg-4 pl-0">
            <label className="font-serif pt-lg-1 size-15 mb-0">Course End Date</label>
            <input type="date" name="enddate" className="form-control acc-form col-lg-11" 
            placeholder="Please Enter" onChange={e => this.handleInputChange(e, i, 'degree')} 
            defaultValue={x.enddate && x.enddate}
            />
            </div>
            <div className="form-group col-lg-4 pl-0">
            <label className="font-serif pt-lg-1 size-15 mb-0">Country</label>
            <input type="text" name="postcode" className="form-control acc-form col-lg-11" 
            placeholder="Please Enter" onChange={e => this.handleInputChange(e, i, 'degree')} 
            defaultValue={x.postcode && x.postcode}
            />
            </div>
            {/* statuses */}
            <h5 className="bold font-serif size-16 mb-1 underline">Course Status:</h5>
            <div className="form-group col-lg-2 pl-0">
            <input type="radio" className="form-group pl-0" id="radio1" name="course_status" 
            value="completed" checked={x.course_status === 'completed' ? true : false} onChange={e => this.handleInputChange(e, i, 'degree')} />
            <label className="font-serif pt-lg-1 size-14 ml-1">Completed</label>
            <label className="form-check-label" for="radio1"></label>
            </div>
            <div className="form-group col-lg-2 pl-0">
            <input type="radio" className="form-group pl-0" id="radio2" name="course_status" 
            value="withdrew" checked={x.course_status === 'withdrew' ? true : false} onChange={e => this.handleInputChange(e, i, 'degree')} />
            <label className="font-serif pt-lg-1 size-14 ml-1">Withdrew</label>
            <label className="form-check-label" for="radio2"></label>
            </div>
            <div className="form-group col-lg-2 pl-0">
            <input type="radio" className="form-group pl-0" id="radio3" name="course_status" 
            value="enrolled" checked={x.course_status === 'enrolled' ? true : false} onChange={e => this.handleInputChange(e, i, 'degree')}   />
            <label className="font-serif pt-lg-1 size-14 ml-1">Currently Enrolled</label>
            <label className="form-check-label" for="radio3"></label>
            </div>
            <div className="d-flex">
            {this.state.degreeList.length !== 1 && <button
            className="btn btn-danger mr-2"  onClick={() => this.handleRemoveClick('degree', i)}>Remove</button>}
            {this.state.degreeList.length - 1 === i &&
            <button className="btn btn-primary" onClick={() => this.handleAddClick('degree')}>Add More</button>}
            </div>
            </div>
            )
            })}
            </>
            </div>
            </div> 
            </div>
            {/* Employeement */}
            <div className="row bg-white area justify-content-center ml-lg-3 mt-3">
            <div className="p-4">
                <h4 className="almost-done mb-3">Employment Details</h4>
            <div className="row mt-2">
            <>
            {this.state.experienceList.map((x, i) => {
            return(
                <div className="row pl-lg-2">
                <h5 className="my-2 bold underline font-serif size-16">Experience {i+1}</h5>
                <div className="form-group col-lg-4 pl-0">
                <label className="font-serif pt-lg-1 size-15 mb-0">Employer Name*</label>
                <input type="text" name="name" className="form-control acc-form col-lg-11" 
                placeholder="Please Enter" onChange={e => this.handleInputChange(e, i, 'experience')}
                defaultValue={x.name && x.name} />
                </div>
                <div className="form-group col-lg-8 pl-0">
                <label className="font-serif pt-lg-1 size-15 mb-0">Your Occupation and Duties</label>
                <input type="text" name="duties" className="form-control acc-form col-lg-11" 
                placeholder="Please Enter" onChange={e => this.handleInputChange(e, i, 'experience')} 
                defaultValue={x.duties && x.duties}
                />
                </div>
                <div className="form-group col-lg-4 pl-0">
                <label className="font-serif pt-lg-1 size-15 mb-0">Country</label>
                <input type="text" name="exp_country" className="form-control acc-form col-lg-11" 
                placeholder="Please Enter" onChange={e => this.handleInputChange(e, i, 'experience')} 
                defaultValue={x.exp_country && x.exp_country}
                />
                </div>
                <div className="form-group col-lg-4 pl-0">
                <label className="font-serif pt-lg-1 size-15 mb-0">Start Date</label>
                <input type="date" name="startdate" className="form-control acc-form col-lg-11" 
                placeholder="Please Enter" onChange={e => this.handleInputChange(e, i, 'experience')} 
                defaultValue={x.startdate && x.startdate}
                />
                </div>
                <div className="form-group col-lg-4 pl-0">
                <label className="font-serif pt-lg-1 size-15 mb-0">End Date</label>
                <input type="date" name="enddate" className="form-control acc-form col-lg-11" 
                placeholder="Please Enter" onChange={e => this.handleInputChange(e, i, 'experience')} 
                defaultValue={x.enddate && x.enddate}
                />
                </div>
                <div className="form-group col-lg-8 pl-0">
                <label className="font-serif pt-lg-1 size-15 mb-0">Address Of Business</label>
                <input type="text" name="business_address" className="form-control acc-form col-lg-11" 
                placeholder="Please Enter" onChange={e => this.handleInputChange(e, i, 'experience')} 
                defaultValue={x.business_address && x.business_address}
                />
                </div>
                <div className="d-flex">
                {this.state.experienceList.length !== 1 && 
                <button className="btn btn-danger mr-2"  onClick={() => this.handleRemoveClick('experience', i)}>Remove</button>}
                {this.state.experienceList.length - 1 === i &&
                <button className="btn btn-primary" onClick={() => this.handleAddClick('experience')}>Add More</button>}
                </div>
                </div>
            )
            })}
            </>
            </div>
            </div> 
            </div>

            </div>
            <div className='row my-4 px-1'>  
            <button type="submit" onClick={this.onSubmit}  className="btn btn-success ml-2 pl-3 pr-3" >Save Changes </button>
            </div>
            </div>
            {/* Assessment & Certifications */}
            <div className={`tab-pane ${activeTab === 'certificate' ? 'show active' : 'hide'}`} id="certificate">
            <div className='mb-3'>
            <div className="row bg-white area justify-content-center ml-lg-3">
            <div className="p-4">
            <h4 className="almost-done mb-3">Assessment & Certifications</h4>
            <div className="row mt-2">
            <>
            {this.state.skillList.map((x, i) => {
            return(
            <div className="row pl-lg-2">
            <h5 className="m-b-0 font-serif size-16 mt-3">Have you applied, currently hold valid skills assessment?</h5>
            {this.data(x.assessingbody, 'assessingbody', i , 'skill', "Country of Issue:*", "col-lg-4" )}
            {this.data(x.referencenumber, 'referencenumber', i , 'skill', "Reference no", "col-lg-4")}
            {this.data(x.assessingdate, 'assessingdate', i , 'skill', "Expiry Date", "col-lg-4", 'date' )}
            {this.data(x.expirydate, 'expirydate', i , 'skill', "Expiry Date", "col-lg-4", 'date' )}
            </div>)
            })}
            </>
            </div>
            </div>
            </div>
            {/* Birth Certificate */}
            <div className='px-2 pt-2 area bg-white my-2 ml-lg-3'>
            <div className='col-12 p-2 px-3'>
            <ClientInfoSwitch status={this.state.birthCertBtn} text="Do you have Birth Certificate?" keyName="birth_certificate" parentCallback={this.handleCallback} />
            {(this.state.birthCertBtn === 1 || this.state.birthCertBtn === '1') &&
            <>
            {this.state.birthCertiList.map((x, i) => {
            return(
                <div className="row pl-lg-2">
                {this.data(x.bci_no, 'bci_no', i , 'birth_certificate', "Birth Certificate Identification Number*", "col-lg-7" )}
                {this.data(x.bci_country, 'bci_country', i , 'birth_certificate', "Country Of Issue", "col-lg-5" )}
                </div>
            )
            })}
            </>}
            </div>
            </div>
            {/* Marriage Certificate */}
            <div className='px-2 pt-2 area bg-white my-2 ml-lg-3'>
            <div className='col-12 p-2 px-3'>
            <ClientInfoSwitch status={this.state.marriageCertBtn} text="Marriage Certificate?" keyName="marriage_certificate" parentCallback={this.handleCallback} />
            {(this.state.marriageCertBtn === 1 || this.state.marriageCertBtn === '1') &&
            <>
            {this.state.marriageCert.map((x, i) => {
            return(
                <div className="row pl-lg-2">
                {this.data(x.first_name, 'first_name', i , 'marriage_certificate', "First Name", "col-lg-4" )}
                {this.data(x.middle_name, 'middle_name', i , 'marriage_certificate', "Middle Name", "col-lg-4" )}
                {this.data(x.last_name, 'last_name', i , 'marriage_certificate', "Last Name", "col-lg-4" )}
                {this.data(x.idientity_no, 'idientity_no', i , 'marriage_certificate', "Identification Number", "col-lg-4" )}
                {this.data(x.marriage_cert_country, 'marriage_cert_country', i , 'marriage_certificate', "Country Of Issue", "col-lg-4" )}
                </div>
                )
            })}
            </>}
            </div>
            </div>
            {/* English Test */}
            <div className='px-2 pt-2 area bg-white my-2 ml-lg-3'>
            <div className='col-12 p-2 px-3'>
            <ClientInfoSwitch status={this.state.englishTestBtn} text="Did you undertake English language test?" keyName="english_test" parentCallback={this.handleCallback} />
            {(this.state.englishTestBtn === 1 || this.state.englishTestBtn === '1') &&
            <>
            {this.state.engTestList.map((x, i) => {
            return(
                <div className="row pl-lg-2">
                <h5 className="my-2 bold underline font-serif size-16">English Test {i+1}</h5>

                {this.data(x.testtype, 'testtype', i , 'english_test', "Test Type*", "col-lg-4" )}

                {this.data(x.referencenumber, 'referencenumber', i , 'english_test', "Reference No#", "col-lg-4" )}

                {this.data(x.issuedate, 'issuedate', i , 'english_test', "Test Date", "col-lg-4", 'date' )}

                {this.data(x.expirydate, 'expirydate', i , 'english_test', "Expiry Date (Education Purpose)", "col-lg-6", 'date' )}

                {this.data(x.expirydatemig, 'expirydatemig', i , 'english_test', "Expiry Date (Migration Purpose)", "col-lg-6", 'date' )}
                
                <div className="d-flex">
                {this.state.engTestList.length !== 1 && 
                <button className="btn btn-danger mr-2"  onClick={() => this.handleRemoveClick('english_test', i)}>Remove</button>}
                {this.state.engTestList.length - 1 === i &&
                <button className="btn btn-primary" onClick={() => this.handleAddClick('english_test')}>Add More</button>}
                </div>
                </div>
            )
            })}
            </>}
            </div>
            </div>
             {/* OSHC / OVHC? */}
            <div className='px-2 pt-2 area bg-white my-2 ml-lg-3'>
            <div className='col-12 p-2 px-3'>
            <ClientInfoSwitch status={this.state.oshcBtn} text="Do you currently hold OSHC / OVHC?" keyName="oshc" parentCallback={this.handleCallback} />
            {(this.state.oshcBtn === 1 || this.state.oshcBtn === '1') &&
            <>
            {this.state.oshcList.map((x, i) => {
            return(
                <div className="row pl-lg-2">
                <h5 className="my-2 bold underline font-serif size-16">OSHC / OVHC {i+1}</h5>
                <div className="form-group col-lg-4 pl-0">
                <label className="font-serif pt-lg-1 size-15 mb-0">Provider Name*</label>
                <input type="text" name="providername" className="form-control acc-form col-lg-11" 
                placeholder="Please Enter" onChange={e => this.handleInputChange(e, i, 'oshc')}
                defaultValue={x.providername && x.providername} />
                </div>
                <div className="form-group col-lg-4 pl-0">
                <label className="font-serif pt-lg-1 size-15 mb-0">Member Number</label>
                <input type="text" name="membernumber" className="form-control acc-form col-lg-11" 
                placeholder="Please Enter" onChange={e => this.handleInputChange(e, i, 'oshc')} 
                defaultValue={x.membernumber && x.membernumber}
                />
                </div>
                <div className="form-group col-lg-4 pl-0">
                <label className="font-serif pt-lg-1 size-15 mb-0">Issue Date</label>
                <input type="date" name="issuedate" className="form-control acc-form col-lg-11" 
                placeholder="Please Enter" onChange={e => this.handleInputChange(e, i, 'oshc')} 
                defaultValue={x.issuedate && x.issuedate}
                />
                </div>
                <div className="form-group col-lg-4 pl-0">
                <label className="font-serif pt-lg-1 size-15 mb-0">Expiry Date</label>
                <input type="date" name="expirydate" className="form-control acc-form col-lg-11" 
                placeholder="Please Enter" onChange={e => this.handleInputChange(e, i, 'oshc')} 
                defaultValue={x.expirydate && x.expirydate}
                />
                </div>
                <div className="d-flex">
                {this.state.oshcList.length !== 1 && 
                <button className="btn btn-danger mr-2"  onClick={() => this.handleRemoveClick('oshc', i)}>Remove</button>}
                {this.state.oshcList.length - 1 === i &&
                <button className="btn btn-primary" onClick={() => this.handleAddClick('oshc')}>Add More</button>}
                </div>
                </div>
            )
            })}
        
            </>}
            </div>
            </div>

            <div className='row my-4 px-1'>  
            <button type="submit" onClick={this.onSubmit}  className="btn btn-success ml-2 pl-3 pr-3" >Save Changes </button>
            </div>

            </div>
            </div>
            {/* Spouse Information */}
            <div className={`tab-pane ${activeTab === 'spouse' ? 'show active' : 'hide'}`} id="spouse">
            <div className='mb-3'>
            <div className="row bg-white area justify-content-center ml-lg-3">
            <div className="p-4">
            <h4 className="almost-done mb-3">Spouse Information</h4>
            <div className="row mt-2">
            <>
            {this.data(this.state.spouse[0]['firstname'], 'firstname', 0 , 'spouse', "Spouse First Name*", "col-lg-3 font-serif pt-lg-1 size-16 mb-0", '','profile-label' )}
            {this.data(this.state.spouse[0]['middlename'], 'middlename', 0 , 'spouse', "Spouse Middle Name", "col-lg-3 font-serif pt-lg-1 size-16 mb-0", "",'profile-label' )}
            {this.data(this.state.spouse[0]['lastname'], 'lastname', 0 , 'spouse', "Spouse Last Name", "col-lg-3 font-serif pt-lg-1 size-16 mb-0", "" ,'profile-label' )}
            {this.data(this.state.spouse[0]['gender'], 'gender', 0 , 'spouse', "Gender", "col-lg-3 font-serif pt-lg-1 size-16 mb-0", "", 'profile-label' )}
            {this.data(this.state.spouse[0]['dob'], 'dob', 0 , 'spouse', "Spouse Date of Birth", "col-lg-3 font-serif pt-lg-1 size-16 mb-0", "date" ,'profile-label' )}
            {this.data(this.state.spouse[0]['birthplace'], 'birthplace', 0 , 'spouse', "Place of Birth", "col-lg-3 font-serif pt-lg-1 size-16 mb-0", "", 'profile-label' )}
            {this.data(this.state.spouse[0]['citizenship'], 'citizenship', 0 , 'spouse', "Citizenship", "col-lg-3 font-serif pt-lg-1 size-16 mb-0", "", 'profile-label' )}
            {this.data(this.state.spouse[0]['residencecountry'], 'residencecountry', 0 , 'spouse', "Country of residence", "col-lg-3 font-serif pt-lg-1 size-16 mb-0", "", 'profile-label' )}
            </>
            </div>
            </div>
            </div>
              {/* Spouse CNIC */}
            <div className='px-2 pt-2 area bg-white my-2 ml-lg-3'>
            <div className='col-12 p-2 px-3'>
            <ClientInfoSwitch status={this.state.sCnicBtn} text="Spouse have National Identity Card?" keyName="spouse_cnic" parentCallback={this.handleCallback} />
            {(this.state.sCnicBtn === 1 || this.state.sCnicBtn === '1') &&
            <>
            {this.state.sCnicList.map((x, i) => {
            return(
                <div className="row pl-lg-2 mb-2">
                {this.data(x.cnicno, 'cnicno', i , 'spouse_cnic', "CNIC No*", "col-lg-4" )}
                {this.data(x.issuedate, 'issuedate', i , 'spouse_cnic', "CNIC Issue Date", "col-lg-4", "date" )}
                {this.data(x.expirydate, 'expirydate', i , 'spouse_cnic', "CNIC Expiry Date", "col-lg-4", "date")}
                </div>
                )
            })}
            </>}
            </div>
            </div>
              {/* Spouse Passport */}
            <div className='px-2 pt-2 area bg-white my-2 ml-lg-3'>
            <div className='col-12 p-2 px-3'>
            <ClientInfoSwitch status={this.state.sPasportBtn} text="Spouse have Passport?" keyName="spouse_passport" div1='col-lg-6 col-9 p-2 j-content-center d-flex border-left-light-gray' parentCallback={this.handleCallback}/>
            {(this.state.sPasportBtn === 1 || this.state.sPasportBtn === '1') &&
                <>
                {this.state.sPassportList.map((x, i) => {
                return(
                    <div className="row pl-lg-2 mb-2">
                    {this.data(x.passportno, 'passportno', i , 'spouse_passport', "Passport No*", "col-lg-3" )}
                    {this.data(x.country, 'country', i , 'spouse_passport', "Country of Passport", "col-lg-3" )}
                    {this.data(x.issuedate, 'issuedate', i , 'spouse_passport', "Passport Issue Date", "col-lg-3", "date" )}
                    {this.data(x.expirydate, 'expirydate', i , 'spouse_passport', "Passport Expiry Date", "col-lg-3", "date")}
                    </div>
                    )
                })}
                </>}  
            </div>
            </div>
              {/* Spouse Education / Qualification */}
            <div className='px-2 pt-2 area bg-white my-2 ml-lg-3'>
            <div className='col-12 p-2 px-3'>
            <ClientInfoSwitch status={this.state.sQualificationBtn} text="Spouse have details of all tertiary education and qualifications?" keyName="spouse_qualification" parentCallback={this.handleCallback} />
            {(this.state.sQualificationBtn === 1 || this.state.sQualificationBtn === '1') &&
            <>
            {this.state.sQualificationList.map((x, i) => {
            return(
                <div className="row pl-lg-2 mb-2">
                {this.data(x.name, 'name', i , 'spouse_qualification', "Qualification Name*", "col-lg-3" )}
                {this.data(x.institute, 'institute', i , 'spouse_qualification', "Institute / University", "col-lg-3")}
                {this.data(x.campus, 'campus', i , 'spouse_qualification', "Campus", "col-lg-3")}
                {this.data(x.postcode, 'postcode', i , 'spouse_qualification', "Address", "col-lg-3")}
                {this.data(x.startdate, 'startdate', i , 'spouse_qualification', "Start Date", "col-lg-3", "date")}
                {this.data(x.enddate, 'enddate', i , 'spouse_qualification', "End Date", "col-lg-3", 'date')} 
                {this.data(x.course_status, 'course_status', i , 'spouse_qualification', "Status", "col-lg-3")} 
                
                <div className="d-flex">
                {this.state.sQualificationList.length !== 1 && 
                <button className="btn btn-danger mr-2"  onClick={() => this.handleRemoveClick('spouse_qualification', i)}>Remove</button>}
                {this.state.sQualificationList.length - 1 === i &&
                <button className="btn btn-primary" onClick={() => this.handleAddClick('spouse_qualification')}>Add More</button>}
                </div>

                </div>
                )
            })}
            </>}
            </div>
            </div>
            {/* Spouse Employemnet */}
            <div className='px-2 pt-2 area bg-white my-2 ml-lg-3'>
            <div className='col-12 p-2 px-3'>
            <ClientInfoSwitch status={this.state.sEmployementBtn} text="Spouse Give Details of All Employments" keyName="spouse_employement" parentCallback={this.handleCallback} div1='col-lg-6 col-9 p-2 j-content-center d-flex border-left-light-gray'/>
            {(this.state.sEmployementBtn === 1 || this.state.sEmployementBtn === '1') &&
            <>
            {this.state.sEmployementList.map((x, i) => {
            return(
                <div className="row pl-lg-2 mb-2">
                {this.data(x.name, 'name', i , 'spouse_employement', "Employer Name*", "col-lg-4" )}
                {this.data(x.duties, 'duties', i , 'spouse_employement', "Your Occupation and Duties", "col-lg-4")}
                {this.data(x.startdate, 'startdate', i , 'spouse_employement', "Start Date", "col-lg-4", "date")}
                {this.data(x.enddate, 'enddate', i , 'spouse_employement', "End Date", "col-lg-4", "date")}
                {this.data(x.business_address, 'business_address', i , 'spouse_employement', "Address Of Business", "col-lg-4")}
                {this.data(x.exp_country, 'exp_country', i , 'spouse_employement', "Country", "col-lg-4")} 
                
                <div className="d-flex">
                {this.state.sEmployementList.length !== 1 && 
                <button className="btn btn-danger mr-2"  onClick={() => this.handleRemoveClick('spouse_employement', i)}>Remove</button>}
                {this.state.sEmployementList.length - 1 === i &&
                <button className="btn btn-primary" onClick={() => this.handleAddClick('spouse_employement')}>Add More</button>}
                </div>

                </div>
                )
            })}
            </>}
            </div>
            </div>
             {/* Spouse Birth Certificate */}
            <div className='px-2 pt-2 area bg-white my-2 ml-lg-3'>
            <div className='col-12 p-2 px-3'>
             <ClientInfoSwitch status={this.state.sBirthCertBtn} text="Spouse have Birth Certificate?" keyName="spouse_birth" parentCallback={this.handleCallback} />
            {(this.state.sBirthCertBtn === 1 || this.state.sBirthCertBtn === '1') &&
                <>
                {this.state.sBirthCertList.map((x, i) => {
                return(
                <div className="row pl-lg-2 mb-2">
                {this.data(x.bci_no, 'bci_no', i , 'spouse_birth', "Birth Certificate Identification Number:*", "col-lg-6" )}
                {this.data(x.bci_country, 'bci_country', i , 'spouse_birth', "Country Of Issue", "col-lg-4")}
                </div>
                )
                })}
                </>}
            </div>
            </div>
             {/* Spouse Driving License */}
            <div className='px-2 pt-2 area bg-white my-2 ml-lg-3'>
            <div className='col-12 p-2 px-3'>
            <ClientInfoSwitch status={this.state.sDrivingBtn} text="Spouse have Driving License?" keyName="spouse_driving" parentCallback={this.handleCallback}
            div1='col-lg-6 col-9 p-2 j-content-center d-flex border-left-light-gray' 
            />
            {(this.state.sDrivingBtn === 1 || this.state.sDrivingBtn === '1') &&
            <>
            {this.state.sDrivingList.map((x, i) => {
            return(
            <div className="row pl-lg-2 mb-2">
            {this.data(x.licenseno, 'licenseno', i , 'spouse_driving', "License No:*", "col-lg-4" )}
            {this.data(x.expirydate, 'expirydate', i , 'spouse_driving', "Date of Expiry", "col-lg-3", "date")}
            </div>
            )
            })}
            </>}
            </div>
            </div>
              {/* Spouse Marriage Certificate */}
            <div className='px-2 pt-2 area bg-white my-2 ml-lg-3'>
            <div className='col-12 p-2 px-3'>
            <ClientInfoSwitch status={this.state.sMarriageBtn} text="Spouse Have Marriage Certificate?" keyName="spouse_marriage" parentCallback={this.handleCallback} />
            {(this.state.sMarriageBtn === 1 || this.state.sMarriageBtn === '1') &&
                <>
                {this.state.sMarriageList.map((x, i) => {
                return(
                <div className="row pl-lg-2 mb-2">
                {this.data(x.first_name, 'first_name', i , 'spouse_marriage', "First Name", "col-lg-3" )}
                {this.data(x.middle_name, 'middle_name', i , 'spouse_marriage', "Middle Name", "col-lg-3")}
                {this.data(x.last_name, 'last_name', i , 'spouse_marriage', "Last Name", "col-lg-3")}
                {this.data(x.idientity_no, 'idientity_no', i , 'spouse_marriage', "Identification Number", "col-lg-3")}
                {this.data(x.marriage_cert_country, 'marriage_cert_country', i , 'spouse_marriage', "Country Of Issue", "col-lg-3")}
                </div>
                )
                })}
                </>}
            </div>
            </div>

            <div className='row my-4 px-1'>  
            <button type="submit" onClick={this.onSubmit}  className="btn btn-success ml-2 pl-3 pr-3" >Save Changes</button>
            </div>

            </div>
            </div>
             {/* Child Information */}
            <div className={`tab-pane ${activeTab === 'child' ? 'show active' : 'hide'}`} id="child">
            <div className='mb-3'>
            <div className="row bg-white area justify-content-center ml-lg-3">
            <div className="p-4">
            <h4 className="almost-done mb-3">Child Information</h4>
            <div className="row mt-2">
            <>
            {this.state.childList.map((x, i) => {
            return(
            <div className="row pl-lg-2">
            <h5 className="my-2 bold underline font-serif size-16">Child {i+1}</h5>
            <div className="form-group col-lg-4 pl-0">
            <label className="font-serif pt-lg-1 size-15 mb-0">First Name*</label>
            <input type="text" name="firstname" className="form-control acc-form col-lg-11" 
            placeholder="Please Enter" onChange={e => this.handleInputChange(e, i, 'child')}
            defaultValue={x.firstname && x.firstname} />
            </div>
            <div className="form-group col-lg-4 pl-0">
            <label className="font-serif pt-lg-1 size-15 mb-0">Middle Name</label>
            <input type="text" name="middlename" className="form-control acc-form col-lg-11" 
            placeholder="Please Enter" onChange={e => this.handleInputChange(e, i, 'child')} 
            defaultValue={x.middlename && x.middlename}
            />
            </div>
            <div className="form-group col-lg-4 pl-0">
            <label className="font-serif pt-lg-1 size-15 mb-0">Last Name</label>
            <input type="text" name="lastname" className="form-control acc-form col-lg-11" 
            placeholder="Please Enter" onChange={e => this.handleInputChange(e, i, 'child')} 
            defaultValue={x.lastname && x.lastname}
            />
            </div>
            <div className="form-group col-lg-4 pl-0">
            <label className="font-serif pt-lg-1 size-15 mb-0">Gender</label>
            <select data-live-search="true" 
            value={x.gender} name="gender"
            onChange={e => this.handleInputChange(e, i, 'child')}  
            className="form-select acc-form col-lg-11">
            <option value=''>Please Select</option>
            {genders.map((value)=> 
            <option key={value.value}
            value={value.value}>{value.title}</option>
            )}
            </select>
            </div>
            <div className="form-group col-lg-4 pl-0">
            <label className="font-serif pt-lg-1 size-15 mb-0">Date of Birth</label>
            <input type="date" name="dob" className="form-control acc-form col-lg-11" 
            placeholder="Please Enter" onChange={e => this.handleInputChange(e, i, 'child')} 
            defaultValue={x.dob && x.dob}
            />
            </div>
            <div className="form-group col-lg-4 pl-0">
            <label className="font-serif pt-lg-1 size-15 mb-0">Birth Place</label>
            <input type="text" name="birthplace" className="form-control acc-form col-lg-11" 
            placeholder="Please Enter" onChange={e => this.handleInputChange(e, i, 'child')} 
            defaultValue={x.birthplace && x.birthplace}
            />
            </div>
            <div className="form-group col-lg-4 pl-0">
            <label className="font-serif pt-lg-1 size-15 mb-0">Citizenship</label>
            <input type="text" name="citizenship" className="form-control acc-form col-lg-11" 
            placeholder="Please Enter" onChange={e => this.handleInputChange(e, i, 'child')} 
            defaultValue={x.citizenship && x.citizenship}
            />
            </div>
            <div className="form-group col-lg-4 pl-0">
            <label className="font-serif pt-lg-1 size-15 mb-0">Residence Country</label>
            <input type="text" name="resiencecountry" className="form-control acc-form col-lg-11" 
            placeholder="Please Enter" onChange={e => this.handleInputChange(e, i, 'child')} 
            defaultValue={x.residencecountry && x.residencecountry}
            />
            </div>
          
            <div className="d-flex">
            {this.state.childList.length !== 1 && <button
            className="btn btn-danger mr-2"  onClick={() => this.handleRemoveClick('child', i)}>Remove</button>}
            {this.state.childList.length - 1 === i &&
            <button className="btn btn-primary" onClick={() => this.handleAddClick('child')}>Add More</button>}
            </div>
            </div>
            )
            })}
            </>
            </div>
            </div>
            </div>
            <div className='row my-4 px-1'>  
            <button type="submit" onClick={this.onSubmit}  className="btn btn-success ml-2 pl-3 pr-3" >Save Changes</button>
            </div>
            </div>
            </div>

             </div>
             </div>
             </div>
             </div>
             </div>
            </>
        )
    }
}
export default Profile