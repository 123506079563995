import React, {useState,useEffect} from "react";
import "./login.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faKey, faUser, } from '@fortawesome/free-solid-svg-icons'
import axios from "axios";
import swal from 'sweetalert';
import $ from 'jquery';
import { useNavigate } from "react-router-dom";
import { main_url, setUserSession } from "../../utilies/Common";


const Login = () => {
  const history = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [hide, setHide] = useState(false);

  useEffect( () => {
    $(window).resize(function() {
      if ($(window).width() < 825) {
         setHide(true);
      } else{
        setHide(false);
      }
    });
  }, []);

  const handleLogin = () => {
    try {
      const data = {
        email:email,
        password:password,
    }
    console.log(data);
      axios.post(main_url()+'/api/v1/checkAuthorization',data)
      .then(res => {
          console.log('res',res);
          if (res.data.status === true ) {
            setUserSession(res.data.token, res.data.client);
            if(localStorage.getItem('token')){
              history('/dashboard');
              window.location.reload(false);
            }
        } else{
            console.log('false status',res.data.message);
            swal(res.data.message, {icon: "error",});      
        }
       })
      } catch (e) {
        console.log(e)
        swal("Oops!", "Something went wrong!", "error");
      }
  };

  return (
    <div className="fill-window" style={{  background: `url('${process.env.PUBLIC_URL}/assets/img/client-login-bg.jpg')`,backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center',opacity:'1'}}>
       <div className="row h-100">
       {!hide && 
        <div className="col-lg-6 col-12">
        <div className="login-header h-100">
        <div className="container-fluid ">
        <h1 className="h-color text-center custom-font4 bold">Welcome <br/> To <br/>Aptitude Migration</h1>
        <small className="cursive-font bold h-color float-right">(Vision to Succeed)</small>
        <div className="logo text-center">
        <img alt="" src={`${process.env.PUBLIC_URL}/assets/img/logo.png`}  width="150" height="auto" />
        </div>
        </div>
        </div>
        </div>}
   
        <div className="col-lg-6 col-12">
        <div className="container centered" >
        <div className="d-flex justify-content-center h-100 mt-xxl-5">
        <div className="card login-card w-75">
        <form className="pl-lg-5 pr-lg-5 p-3 pb-3 pt-5" action='' onSubmit={handleLogin}>
            {/* show email input */}
        <div className="row p-1">
        {hide && 
         <div className="">
         <h5 className="h-color text-center custom-font4 bold mb-5">Welcome <br/> To <br/>Aptitude Migration</h5> 
         </div>}
        <div className="input-group form-group col-12">
        <div className="input-group-prepend">
            <span className="input-group-text"><FontAwesomeIcon icon={faUser} /></span>
        </div>
        <input className="form-control login-form-control" type="email" name="email" value={email} id="email" placeholder="Enter Email" autoComplete="on"
        onChange={(e) => setEmail(e.target.value) }  /> 
      
        </div>
        </div>
            {/* show password input */}
        <div className="row p-1">
        <div className="input-group form-group col-12">
        <div className="input-group-prepend">
        <span className="input-group-text"><FontAwesomeIcon icon={faKey} /></span>
        </div>
        <input className="form-control login-form-control" type="password" name="password" id="password" placeholder="Enter Password"  autoComplete="on" value={password} 
        onChange={(e) => setPassword(e.target.value) }  />
      
        </div>
        </div>
            {/* login button */}
        <div className="form-group j-content-right text-right pt-2 pb-2">
        <input className={`btn float-right login_btn ${loading ? 'bg-theme-green': 'bg-theme-blue'}`} type="button" value={loading ? 'Loading...' : 'Login'} onClick={handleLogin} disabled={loading} /><br></br>
        </div>
        </form>
        </div>
        </div>
        </div>
        </div>
     </div>


    </div>
  );
};

export default Login;
