import axios from "axios";
import { main_url } from "../utilies/Common"

const token = localStorage.getItem("token");
export const apiUrl = axios.create({
        baseURL: main_url()+'/api/v1/',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Access-Control-Allow-Methods': 'POST, PUT, DELETE, GET, OPTIONS',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Origin': '*',
        },
});
    