
import React, { Component } from "react";

class ClientInfoSwitch extends Component {

     handleChange = (e,keyName) => {
         this.props.parentCallback(e.target.value,keyName);
        }

 render(){
    return(
        <>
         <div className="d-flex">
         <h6 className="m-b-0 font-serif col-lg-8 pl-1">{this.props.text}</h6>

         <div className="form-check font-serif">
         <input type="radio" onChange={(e) => this.handleChange(e,this.props.keyName)} 
         className="form-check-input " id="radio1" name={this.props.keyName} value={1} checked={this.props.status === 0 || this.props.status === '0' ? false : true} />Yes
         <label className="form-check-label" htmlFor="radio1"></label>
         </div>
         <div className="form-check ml-2 font-serif">
         <input type="radio" onChange={(e) => this.handleChange(e,this.props.keyName)} 
         className="form-check-input" id="radio2" name={this.props.keyName} value={0}  checked={this.props.status === 1 || this.props.status === '1' ? false : true} />No
         <label className="form-check-label" htmlFor="radio2"></label>
         </div>
         </div>
        </>
    )
  }
 }
export default ClientInfoSwitch;