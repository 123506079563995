import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './dashboard.css'
import { get_ApiManager } from '../ApiManager';
import useWhyDidYouRender from '../../utilies/useWhyDidYouRender';
import { FaAvianex, FaFileInvoiceDollar, FaRocketchat, FaSchool, FaUserEdit } from 'react-icons/fa';
  
const Dashboard = (props) => {
  const [eduCount, setEduCount] = useState('');
  const [migCount, setMigCount] = useState('');
  const [inboxCount, setInboxCount] = useState('');

  useWhyDidYouRender('MyComponent', props);
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      getDetail();
    }
  },[])

  const getDetail = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    let url = `getDashboardDetails/${user['id']}`;
    console.log(url)
    get_ApiManager('', url, (res) => {
      console.log('dashboard',res);
      setEduCount(res.data.data['edu']);
      setMigCount(res.data.data['mig']);
      setInboxCount(res.data.data['inbox'])

    },(err) => {
      console.log(err);
   });
  }
  
  return (
     <div className="row px-3 py-4">
     {/* Profile */}
     <div className="col-md-6 col-sm-12 col-lg-6 col-xl-3 col-xs-12 padding align-items-stretch">
      <Link className="no-underline" to={'/profile'}>
      <div className='card mb-1 widget-content bg-happy-darkgreen'>
      <div className='widget-content-wrapper text-white bluehover'>
      <div className='widget-content-left my-2'>
      <div className="widget-heading">Profile</div>
      <div className="widget-subheading size-14">Update Your Profile</div>
      </div>
      <div className='widget-content-right'>
      <FaUserEdit className='dash-icon'  />
      </div>
      </div>
      </div>
      </Link>
     </div>
     {/* Education */}
     <div className="col-md-6 col-sm-12 col-lg-6 col-xl-3 col-xs-12 padding align-items-stretch">
      <Link className="no-underline" to={"/education-apps"}>
      <div className='card mb-1 widget-content bg-happy-green'>
      <div className='widget-content-wrapper text-white bluehover'>
      <div className='widget-content-left my-2'>
      <div className='d-flex'>
      <div className="widget-heading">Education</div>
      <div className="yellow-text pl-1">({eduCount})</div>
      </div>
      <div className="widget-subheading size-14">View Education App</div>
      </div>
      <div className='widget-content-right'>
      <FaSchool className='dash-icon' />
      </div>
      </div>
      </div>
      </Link>
     </div>
     {/* Migration */}
    <div className="col-md-6 col-sm-12 col-lg-6 col-xl-3 col-xs-12 padding align-items-stretch">
    <Link className="no-underline" to={'/migration-apps'}>
    <div className='card mb-1 widget-content bg-night-sky'>
    <div className='widget-content-wrapper text-white bluehover'>
    <div className='widget-content-left my-2'>
    <div className='d-flex'>
    <div className="widget-heading">Migration</div>
    <div className="yellow-text pl-1">({migCount})</div>
    </div>
    <div className="widget-subheading size-14">View Migration App</div>
    </div>
    <div className='widget-content-right'>
    <FaAvianex className='dash-icon' />
    </div>
    </div>
    </div>
    </Link>
    </div>
     {/* Payment */}
     <div className="col-md-6 col-sm-12 col-lg-6 col-xl-3 col-xs-12 padding align-items-stretch">
      <Link className="no-underline" to={'/payment'}>
      <div className='card mb-1 widget-content bg-grow-early'>
      <div className='widget-content-wrapper text-white bluehover'>
      <div className='widget-content-left my-2'>
      <div className="widget-heading">Payment</div>
      <div className="widget-subheading size-14">View Payment History</div>
      </div>
      <div className='widget-content-right'>
      <FaFileInvoiceDollar className='dash-icon' />
      </div>
      </div>
      </div>
      </Link>
    </div>
      {/* Inbox */}
     <div className="col-md-6 col-sm-12 col-lg-6 col-xl-3 align-items-stretch col-xs-12 padding">
      <Link className="no-underline" to={'/inbox'}>
      <div className='card mb-1 widget-content bg-premium-dark'>
      <div className='widget-content-wrapper text-white bluehover'>
      <div className='widget-content-left my-2'>
      <div className="widget-heading">Inbox</div>
      <div className="widget-subheading size-14">View Inbox</div>
      </div>
      <div className='widget-content-right'>
      <div className="icon-container">
      <FaRocketchat color='#f7b924' className='dash-icon' />
      <span className="badge">{inboxCount}</span>
      </div>
      </div>
      </div>
      </div>
      </Link>
    </div>
    </div>
  );
}

export default Dashboard
