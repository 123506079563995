import React from 'react';
import './sidebar.css'; // Assuming your Sidebar styles are here
import {BsArrowLeftSquareFill, BsArrowRightCircleFill} from "react-icons/bs";
import logo from '../../assets/logo1.png';
import { Link } from 'react-router-dom';
import { FaAvianex, FaFileInvoiceDollar, FaHome, FaRocketchat, FaSchool } from 'react-icons/fa';

function Sidebar({ isExpanded, hideSideBar, toggleSidebar }) {
  return (
    <>
    {hideSideBar  &&
    <div className={`sidebar ${isExpanded ? 'expanded' : 'collapsed'}`}>
     {isExpanded ? 
      <BsArrowLeftSquareFill onClick ={toggleSidebar} className="nav-collapse"  /> 
      : 
      window.innerWidth > 768 &&
      <BsArrowRightCircleFill onClick ={toggleSidebar} className="open-nav"  />}
      {/* <button onClick={toggleSidebar} className="toggle-btn">
        ...
      </button> */}
      <div className="sidebar-content">
      {!isExpanded === false ? 
      <div id="logo" className="sidebar-brand-text mx-3 p-4"> 
          <img src={logo} alt="Logo" width="110px"/>
      </div>
      :
      <div className="logo text-center pt-2 size-11">
      <img src={`${process.env.PUBLIC_URL}/assets/img/logo.png`} alt=''  width="30" height="auto" />
      <br/>
      <small className="white pt-2 custom-font4">Aptitude Migration</small>
      </div>}

        {/* Your sidebar content goes here */}
        {/* Menu items */}
      <div className="menu size-12 white custom-font4 mt-4 w-100">
        <Link className="no-underline menu-item" to={'/dashboard'}>
        <FaHome className='dash-icon' />
          <span>Dashboard</span>
        </Link>
        <Link to="/education-apps" className="menu-item">
         <FaSchool className='dash-icon' />
          <span>Education</span>
        </Link>
        <Link to="/migration-apps" className="menu-item">
          <FaAvianex className='dash-icon' />
          <span>Migration</span>
        </Link>
        <Link to="/inbox" className="menu-item">
        <FaRocketchat color='#f7b924' className='dash-icon' />
          <span>Inbox</span>
        </Link>
        <Link to="/payment" className="menu-item">
        <FaFileInvoiceDollar className='dash-icon' />
          <span>Payment</span>
        </Link>
      </div>
      </div>
    </div>}
    </>
  );
}

export default Sidebar;
