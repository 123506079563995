export const genders = [
    {
        title: 'Male',
        value: '0'
    },
    {
        title: 'Female',
        value: '1'
    }
];

export const migrationType = [
    {
        noicc: 'Notice of Intention to Consider Cancellation (NOICC) of Visa',
        eoi: 'Expression of Interest (EOI)',
        eoi_189: 'Expression of Interest (EOI) - 189',
        eoi_190: 'Expression of Interest (EOI) - 190',
        eoi_491: 'Expression of Interest (EOI) - 491',
        roi: 'Registration of Interest (ROI)',
    }
];
export const gstbycountry = [
    {
        title   : 'AUD',
        value   : 10,
    },
    {
        title   : 'USD',
        value   : 18,
    },
    {
        title   : 'PKR',
        value   : 17,
    },
    {
        title   : 'CAD',
        value   : 5,
    },
    {
        title   : 'MYR',
        value   : 10,
    },
    {
        title   : 'GBP',
        value   : 20,
    },
    {
        title   : 'SGD',
        value   : 7,
    },
]

const Constants = { genders, migrationType, gstbycountry };

export default Constants;
