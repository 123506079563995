

import React, { Component } from 'react';
import Title from '../GenericComponents/Title';
import { get_ApiManager} from '../ApiManager';
import moment from 'moment'
import Loader from '../GenericComponents/Loader';
import { Link } from 'react-router-dom';

class Payment extends Component {
    constructor(props) {
        super(props);
        this.state = {
          loading: false,
          user: JSON.parse(localStorage.getItem('user')),
          allCost:[],
          headers: [
            {title: '#' , dataKey: "index", align: "text-center" , key:1},
            {title: 'Application', dataKey: "coursename", align: "text-center" ,key:2},
            {title: 'InvoiceNo' , dataKey: "invoice_No",align: "text-left", key:3},
            {title: 'InvoiceDate', dataKey: 'invoice_date',align:"text-center",key:4},
            {title: 'DueDate', dataKey: 'due_date',align:"text-center",key:5},
            {title: 'Paid', dataKey: 'paid',align:"text-center",key:6},
            {title: 'Credit', dataKey: 'credit',align:"text-center",key:7},
            {title: 'Remaining', dataKey: 'remaining',align:"text-center",key:8},
            {title: 'Currency', dataKey: 'currency',align:"text-center",key:9},
            {title: 'Status', dataKey: 'status',align:"text-center",key:10},
            {title: 'Installments', dataKey: 'installments',align:"text-center",key:11},
        ],
        }
    }

    componentDidMount() {
        this.getCost();
    }
    
    async getCost() {
        const user = JSON.parse(localStorage.getItem('user'));
        this.setState({loading: true});
        let url = `getPayments/${user['id']}`;
        get_ApiManager('', url ,(res) => {
          console.log('cost',res);
          this.setState({
            loading: false,
            allCost: res.data.data,
          });
          },(err) => {
              console.log(err);
        });
    }
         // CallBack function handle pagination
    handlePaginationCallback = (i, perPage) =>{
        if(perPage){
            this.setState({ perPage : perPage, currentPage:1}, () => {  this.getCost();  }); 
        } else {this.setState({ currentPage : i}, () => { this.getCost(); }); }
    }

    render(){
        return(
        <>
        <div className="row px-3">
        {this.state.loading ? <Loader /> :
        <>
        <Title title={'Payment'} back={true} />
        <>
        <div className="table-responsive mt-3">
        <table className="table table-bordered bg-white">
          {/* Headers */}
        <thead className="thead-dark">
        <tr>
        {this.state.headers.map(head => (
        <th id={head.dataKey} className={head.align} onClick={() => this.sorting(head.dataKey)} key={head.key}>
        {head.title}</th>
        ))}
        </tr>
        </thead>
        {/* End Headers */}
        <tbody>
        {this.state.allCost.map((value, index) => {
            let remaining = value.remaining;
            return(
            <tr key={index}>
            <td className='text-center'>{++index}</td>
            <td className='text-center name-text'>
            {(value.courseid === 0 || value.courseid === '0') ? value.packagename: value.coursename}
            </td>
            <td className='text-center name-text'>
            <Link target="_blank"
            to={`/preview/invoice?edit=${true}&invoiceNo=${value.invoice_id}`}>
            {value.invoice_No && 'INV-'+value.invoice_No}</Link></td>
            <td className='text-center w-15'>{value.invoice_date && moment(value.invoice_date).format('D MMMM YYYY')}</td>
            <td className='text-center w-15'>{value.plan_duedate ? moment(value.plan_duedate).format('D MMMM YYYY') : moment(value.due_date).format('D MMMM YYYY')}</td>
            <td className='text-center'>{value.paid}</td>
            <td className='text-center'>{value.credit}</td>
            <td className='text-center'>
            {value.type === "Education" ? 
            <Link target="_blank" to={`/education-application/${value.appid}`}>
            {remaining}</Link>
            :
            <Link target="_blank" to={`/migration-application/${value.appid}`}>
            {remaining}</Link>}</td>
            <td className='text-center'>{value.currency}</td>
            <td className={`text-center bold sira-font size-12 
            ${value.status === 'Paid' ? 'active' : 
            value.status === 'Over Paid' ? 'heading-blue' : 
            value.status === 'Awaiting Payment' ? 'text-red':''}`}> {value.status} </td>
            <td className='text-center'>{value.installments > 0 ? value.installments : 'N/A'}</td>
            </tr> )}
        )}
        </tbody>
        </table>
        </div>
        </>

        </>}
        </div>
        </>)
    }
}
export default Payment;